import { makeStyles } from "tss-react/mui";

export const useBiddingTableStyles = makeStyles()((theme) => ({
    headerContainer: {
        position: 'relative',
        backgroundColor: "#2b2b2b",
        borderRadius: 3,
        height: "75px"
    },
    countDownContainer: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
    },
    countDownText: {
        fontSize: '10px'
    },
    biddingHistoryTable: {
        display: 'block',
        height: '200px',
        overflowY: 'scroll'
    },
    biddingHistoryTableRow: {
        padding: '4px',
        height: '30px'
    },
    countdownTimer: {
        background: "#2b2b2b",
        flex: 1,
        padding: 1,
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down(960)]: {
            paddingLeft: theme.spacing(1.5),
        },
        [theme.breakpoints.down(430)]: {
            paddingLeft: theme.spacing(1.5),
            padding: theme.spacing(0.5),
        },
    },
    statusText: { backgroundColor: 'green', padding: '3px', borderRadius: '15px', width: 50, fontSize: '10px', textAlign: 'center', color: 'white' },
    countDownRenderer: {
        display: "flex",
        justifyContent: "space-around",
    },
    countDownRendererUnitItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    countDownRendererUnitValue: {
        fontSize: "0.8rem",
    },
    countDownRendererUnitSuffix: {
        fontSize: "0.8rem",
        paddingRight: 5,
        paddingLeft: 2,
        [theme.breakpoints.down(430)]: {
            fontSize: "0.5rem",
        },
    },
    countDownRenderDisplayText: {
        textAlign: 'center',
        fontSize: "0.7rem",
        [theme.breakpoints.down(430)]: {
            fontSize: "0.5rem",
        },
    },
    leftRightTextContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));