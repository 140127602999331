import { FC, memo } from "react";
import { Tab } from "@mui/material";
import { useTabBoxStyles } from "../TabBox.styles";

export interface TabBoxProps {
  value: string;
  label: string;
  onClick?: () => void;
}

const TabBoxItem: FC<TabBoxProps> = ({ value, label, onClick }) => {

    const { classes } = useTabBoxStyles();

  return (
      <Tab
          label={label}
          value={value}
          classes={{
              root: classes.tabListItemRoot,
          }}
          onClick={onClick}
      />
  );
};

export default memo(TabBoxItem);