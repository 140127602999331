import {
    Backdrop,
    Card,
    Fade,
    Modal,
} from '@mui/material';
import ReactDOM from 'react-dom';
import React, { FunctionComponent } from 'react';

import LoginForm from "../LoginForm/LoginForm";
import RegisterCard from "../RegisterCard/RegisterCard";
import useStyles from "./LoginModalStyles";

interface Props {
    isShown: boolean;
    hide: () => void;
    isMobile?: boolean;
};

export const LoginRegisterModal: FunctionComponent<Props> = ({
    isShown,
    hide,
    isMobile = false
}) => {
    const { classes } = useStyles();
    const [isLoginModal, setIsLoginModal] = React.useState<boolean>(true);

    const handleHide = (hideModal: boolean) => {
        setIsLoginModal(true);
        if (hide) hide();
    }

    const handleIsLogin = (isLogin) => {
        setIsLoginModal(isLogin);
    }

    const modal = (
        <Modal
            id={"modal-window"}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isShown}
            className={classes.modal}
            onClose={handleHide}>
            <Fade in={isShown}>
                <div>
                    <Card className={classes.loginCard} raised={true}>
                        {isLoginModal ?
                            <LoginForm navigateOnLogin={false} isLogin={(isLogin) => handleIsLogin(isLogin)} isMobile={isMobile} handleHide={hide} />
                            : <RegisterCard isLogin={(isLogin) => handleIsLogin(isLogin)} />}
                    </Card>
                </div>
            </Fade>
        </Modal>
    );
    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
}
export default LoginRegisterModal;