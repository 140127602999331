import { makeStyles } from "tss-react/mui";

export const useLotBidAndInfoSectionStyles = makeStyles()((theme) => ({
    imageContainer: {
        position: 'relative',
        width: '100%'
    },
    validatingContainer: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        padding: '20px',
        borderRadius: '8px',
    },
    soldBannerContainer: {
        position: 'absolute',
        width: '100%',
        top: '42%',
        zIndex: 3
    }
}));
