import { useMemo, memo } from 'react';
import BidButton from '@/components/Buttons/BidButton';
import { ceil } from 'lodash';
import { Skeleton } from '../../library/Skeleton';

type BidButtonContainerProps = {
    showLoading?: boolean;
    commission: number | null | undefined;
    biddingCalculation: any;
    increment: number;
    topBidderAmount: number;
    startingPrice: number;
    auctionId: string;
    lotId: string;
    onSuccess: (amount: number, paddleNumber: string) => void;
}

const BidButtonContainer = (props: BidButtonContainerProps) => {
    const {
        showLoading = false,
        commission,
        biddingCalculation,
        increment,
        topBidderAmount,
        startingPrice,
        auctionId,
        lotId,
        onSuccess
    } = props;

    const bidAmount = useMemo(() => {
        if (Number(topBidderAmount) > 0) return ceil(Number(increment) + Number(topBidderAmount), 2);
        else if (Number(startingPrice) > 0) return ceil(Number(startingPrice), 2);
        else return ceil(Number(increment), 2);
    }, [increment, startingPrice, topBidderAmount])

    return (<>
        {
            showLoading ? (
                <Skeleton className="mb-3" animation="wave" variant="rectangular" height="56px" width="100%" />
            ) : (
                    <BidButton
                        onBidSuccessfullySubmitted={(data) => onSuccess(bidAmount, data?.paddleNumber)}
                        key="bidding"
                        auctionId={auctionId}
                        lotId={lotId}
                        bidAmount={bidAmount}
                        commission={commission}
                        biddingCalculation={biddingCalculation}
                    />
            )
        }
        </>
    )
}

export default memo(BidButtonContainer);