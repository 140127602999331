import { useMemo, memo, useState, useEffect } from 'react';
import { ceil } from 'lodash';
import MaximumBidButton from '@/components/Buttons/MaximumBidButton';
import { Skeleton } from '../../library/Skeleton';

type MaxBidButtonContainerProps = {
    showLoading?: boolean;
    commission: number | null | undefined;
    biddingCalculation: any;
    increment: number;
    topBidderAmount: number;
    startingPrice: number;
    auctionId: string;
    lotId: string;
    onSuccess: (amount: number) => void;
    maximumBidAmount: number;
}

const MaxBidButtonContainer = (props: MaxBidButtonContainerProps) => {
    const {
        showLoading = false,
        commission,
        biddingCalculation,
        increment,
        topBidderAmount,
        startingPrice,
        auctionId,
        lotId,
        onSuccess,
        maximumBidAmount
    } = props;

    const [amount, setAmount] = useState<any>(null);

    useEffect(() => {
        setAmount(maximumBidAmount);
    }, [maximumBidAmount]);

    const bidAmount = useMemo(() => {
        if (Number(topBidderAmount) > 0) return ceil(Number(increment) + Number(topBidderAmount), 2);
        else if (Number(startingPrice) > 0) return ceil(Number(startingPrice), 2);
        else return ceil(Number(increment), 2);
    }, [increment, startingPrice, topBidderAmount])

    return (<>
        {
            showLoading ? (
                <Skeleton className="mb-2" animation="wave" variant="rectangular" height="56px" width="100%" />
            ) : (
                <MaximumBidButton
                    onMaxBidSuccessfullySubmitted={onSuccess}
                    onMaximumBidSubmitted={setAmount}
                    auctionId={auctionId}
                    lotId={lotId}
                    maximumBidAmount={amount}
                    automaticBidAmount={bidAmount}
                    commission={commission}
                    biddingCalculation={biddingCalculation}
                />
            )
        }
    </>
    )
}

export default memo(MaxBidButtonContainer);