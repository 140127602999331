import { memo } from 'react';
import TenderButton from '@/components/Buttons/TenderButton';
import { Skeleton } from '../../library/Skeleton';

type TenderBidButtonContainerProps = {
    showLoading?: boolean;
    auctionId: string;
    lotId: string;
    onSuccess: (amount: number) => void;
    tenderAmount: number;
}

const TenderBidButtonContainer = (props: TenderBidButtonContainerProps) => {
    const {
        showLoading = false,
        auctionId,
        lotId,
        onSuccess,
        tenderAmount
    } = props;

    return (<>
        {
            showLoading ? (
                <Skeleton className="mb-3" animation="wave" variant="rectangular" height="56px" width="100%" />
            ) : (
                <TenderButton
                    onTenderSuccessfullySubmitted={onSuccess}
                    auctionId={auctionId}
                    lotId={lotId}
                    tenderAmount={tenderAmount}
                />
            )
        }
    </>
    )
}

export default memo(TenderBidButtonContainer);