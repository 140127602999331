import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleClearAutoWishlistData, handleSubmit, loginResetState } from './store/LoginForm';
import useStyles from './LoginFormStyles';
import ns from '@/helpers/NotificationService';
import {
    Button,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    OutlinedInput,
    InputLabel,
    FormControl,
    Grid,
    List,
    ListItem,
    Alert
} from '@mui/material';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { isValidEmail } from '../../helpers/Utils';
import { ConfirmationDialog } from "@/components/Modal/confirmationModal";
import { handleRemoveAccountDeletion } from "./../../pages/Profile/store/Profile";
import { handleLotWishlistDataUpsert, handleLotWishlistItemStatusCheck, handleSetWishlistBiddingNotification } from '../../pages/MyActivity/store/MyActivity';
import { PrembidLocalStorage } from "@/helpers/PrembidLocalStorage";
import { toggleWishlist } from '../../pages/Lots/store/Lots';
import useHistory from '../../helpers/hooks/useHistory';

interface Props {
    handleRemoveAccountDeletion_d: (email: string, onCompletedCallback: (response: any) => void) => void,
    handleSubmit_d: (email: string, password: string, onCompletedCallback: (result: any) => void) => any,
    loginResetState_d: () => void,
    handleLotWishlistDataUpsert_d: (myWishlistData: any, onCompletedCallback?: (res: any) => void) => void,
    handleToggleWishList_d: (lotId: string) => void,
    handleClearAutoWishlistData_d: () => void,
    handleLotWishlistItemStatusCheck_d: (profileId: any, lotId: any, onCompletedCallback: (result: any) => void) => void,
    handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => void,
    wishlistNotificationFlagObject: any,
    error: boolean,
    errorText: string,
    token: any,
    success: boolean,
    navigateOnLogin: boolean,
    settings: any,
    loading: boolean,
    isLogin: (isLogin: boolean) => void;
    handleHide?: () => void,
    isMobile?: boolean;
    autoWishlistData: any,
}

const LoginCard: React.FC<Props> = props => {

    const history = useHistory();

    const {
        handleRemoveAccountDeletion_d,
        handleSubmit_d,
        loginResetState_d,
        handleLotWishlistDataUpsert_d,
        handleToggleWishList_d,
        handleClearAutoWishlistData_d,
        handleLotWishlistItemStatusCheck_d,
        handleSetWishlistBiddingNotification_d,
        wishlistNotificationFlagObject,
        error,
        errorText,
        success,
        navigateOnLogin,
        settings,
        loading,
        isLogin,
        handleHide,
        isMobile = false,
        autoWishlistData,
    } = props;

    const { classes } = useStyles();

    const intialValues = { email: "", password: "" };

    const [formFields, setFormFields] = useState(intialValues);
    const [formErrors, setFormErrors] = useState(intialValues);
    const [showPassword, setShowPassword] = useState(false);
    const [imgSrc, setImgSrc] = React.useState<any>(undefined);
    const [cancelDeletionModalState, setCancelDeletionModalState] = React.useState<any>({
        open: false,
        id: ''
    });

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const onSubmit = () => {
        const onSubmitErrors = validate(formFields);
        setFormErrors(onSubmitErrors);

        if (onSubmitErrors.email.length === 0 && onSubmitErrors.password.length === 0) {
            showProgressSpinner({ description: 'Logging In...' });
            handleSubmit_d(formFields.email, formFields.password, (result: any) => {

                hideProgressSpinner(100);

                if (autoWishlistData.lotId && PrembidLocalStorage.currentProfile !== '') {
                    if (handleHide) handleHide();
                    const data = {
                        lotId: autoWishlistData?.lotId,
                        profileId: PrembidLocalStorage.currentProfile,
                    }
                    handleLotWishlistItemStatusCheck_d(PrembidLocalStorage.currentProfile, autoWishlistData?.lotId, function (res) {
                        if (res.response === false) {
                            handleLotWishlistDataUpsert_d(data, function (res) {
                                if (res.success) {
                                    handleToggleWishList_d(autoWishlistData?.lotId);
                                    let payload = { ...wishlistNotificationFlagObject }
                                    payload.wishlistedActivity = true;
                                    handleSetWishlistBiddingNotification_d(payload);
                                }
                                else {
                                    ns.error("", "Failed to wishlist item, please try again.");
                                }
                                handleClearAutoWishlistData_d();
                            })
                        } else {
                            handleClearAutoWishlistData_d();
                        }
                    })
                }
            });
        }
    }

    const handleRegisterClick = () => {
        isLogin(false);
    };

    useEffect(() => {
        hideProgressSpinner();

        if (success) {
            if (navigateOnLogin)
                login();
        }
        loginResetState_d();

    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) {
            if (errorText.includes("scheduled for deletion")) {
                setCancelDeletionModalState({ open: true });
            } else {
                ns.error('', errorText);
            }
        }
        loginResetState_d();

    }, [error]);

    React.useEffect(() => {
        setImgSrc(settings.VendorLogoUrl)
    }, [settings])

    const validate = (values) => {
        let errors = { email: '', password: '' };

        if (!values.email) {
            errors.email = "Email field cannot be blank";
        } else if (!isValidEmail(values.email.replace(" ", ""))) {
            errors.email = "Please enter a valid email address.";
        }

        if (!values.password) {
            errors.password = "Password field cannot be blank";
        }

        return errors;
    };

    const login = () => {
        let path = '/home';
        history.push(path);
    }

    const forgotPassword = () => {
        let path = '/forgotPassword';
        history.push(path);
    }

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const ChromeInstallationOnClick = () => {
        window.open('https://www.google.com/chrome/?brand=WHAR&gclid=EAIaIQobChMI766o_7y99QIV7oBQBh11nQYnEAAYASAAEgLscPD_BwE&gclsrc=aw.ds');
    }

    const EdgeInstallationOnClick = () => {
        window.open('https://www.microsoft.com/en-us/edge');
    }

    const FireFoxInstallationOnClick = () => {
        window.open('https://www.mozilla.org/en-US/firefox/new/');
    }

    const handleCancelDeletionOk = () => {
        handleCancelDeletionCancel();

        showProgressSpinner({ description: "Updating Account..." });
        handleRemoveAccountDeletion_d(formFields.email, (res) => {
            if (res.success) {
                onSubmit();
            }
            else {
                ns.error("", "Failed to update the account")
                hideProgressSpinner();
            }
        });
    }

    const handleCancelDeletionCancel = () => {
        setCancelDeletionModalState({ open: false });
    }

    return (<>
        <ConfirmationDialog
            open={cancelDeletionModalState.open}
            payload={cancelDeletionModalState.id}
            okButtonText="Ok"
            cancelButtonText="Cancel"
            description={errorText}
            title="Continue"
            onCancel={handleCancelDeletionCancel}
            onOk={handleCancelDeletionOk}
        />

        {!loading && <>
            <Grid container spacing={2} direction="row" justifyContent="flex-start">
                <Grid item md={4} className="bg-primary p-3">
                    <h4 className=" text-white text-center" style={{ paddingTop: isMobile ? '16px' : '140px' }}>Don't have an Account?</h4>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained" className="btn-primary p-3 text-white text-capitalize border-top border-left border-right border-bottom"
                            type="submit"
                            onClick={handleRegisterClick}>
                            Sign Up
                        </Button>
                    </div>
                </Grid>
                <Grid item md={8} className={isMobile ? classes.paperMobileContainer : "p-4"}>
                    <div className={isMobile ? classes.paperMobile : classes.paper} style={{ marginLeft: "29" }}>
                        <img className={classes.imgLogo + ' mb-4 center'} src={imgSrc} />
                        <h4 className=" text-black text-center">Existing User? Log In</h4>

                        <TextField
                            margin="dense"
                            fullWidth
                            id="email"
                            value={formFields.email}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            label="Email"
                            variant="outlined"
                            autoFocus={true}
                            onKeyDown={(e) =>
                                (e.key === " ") && e.preventDefault()
                            }
                        />

                        {formErrors.email && (<Alert severity='error'>{formErrors.email}</Alert>)}

                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                value={formFields.password}
                                onChange={onChange}
                                onKeyDown={onKeyPress}
                                required
                                id="password"
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleTogglePassword}
                                        >
                                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />

                        </FormControl>
                        {formErrors.password && (<Alert severity='error'>{formErrors.password}</Alert>)}
                        <Button
                            variant="contained" className="btn-primary p-3 mt-4 text-white text-capitalize"
                            type="submit"
                            onClick={onSubmit}>
                            Log In
                        </Button>

                        <Link className={classes.link + " text-info text-center mt-2 mb-2"} style={{ textDecoration: "underline" }} onClick={forgotPassword} >Forgot Password?</Link>
                    </div>
                </Grid>
            </Grid>
        </>}
    </>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (email: string, password: string, onCompletedCallback: (result: any) => void) => dispatch(handleSubmit(email, password, onCompletedCallback)),
        handleRemoveAccountDeletion_d: (email: string, onCompletedCallback: (response: any) => void) => dispatch(handleRemoveAccountDeletion(email, onCompletedCallback)),
        loginResetState_d: () => dispatch(loginResetState()),
        handleLotWishlistDataUpsert_d: (myWishlistData: any, onCompletedCallback?: (res: any) => void) => dispatch(handleLotWishlistDataUpsert(myWishlistData, onCompletedCallback)),
        handleToggleWishList_d: (lotId: string) => dispatch(toggleWishlist(lotId)),
        handleClearAutoWishlistData_d: () => dispatch(handleClearAutoWishlistData()),
        handleLotWishlistItemStatusCheck_d: (profileId: any, lotId: any, onCompletedCallback: (result: any) => void) => dispatch(handleLotWishlistItemStatusCheck(profileId, lotId, onCompletedCallback)),
        handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetWishlistBiddingNotification(payload, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    success: state.loginForm.success,
    error: state.loginForm.error,
    errorText: state.loginForm.errorText,
    token: state.loginForm.token,
    settings: state.settings.settings,
    loading: state.progressSpinner.loading,
    autoWishlistData: state.loginForm.autoWishlistData,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard)
