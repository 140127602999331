import { useSelector } from 'react-redux';
import { RootState } from '@/store/Store';
import { useRetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile, useRetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess, useRetrieveLotDetailsPageData } from 'prembid-shared-library-npm/networking';
import { ImageAndVideoCarousel } from '@/components/ImageAndVideoCarousel';
import ns from "@/helpers/NotificationService";
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { showProgressSpinner, hideProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { SectionTitle } from '@/components/SectionTitle';
import { GoogleMaps } from '../GoogleMaps';
import { useLotFeatures } from "prembid-shared-library-npm/hooks";
import { DocResultValue, DocTemplateResultValue, DocumentTypeEnum } from 'prembid-shared-library-npm/types';
import { PrembidLocalStorage } from "@/helpers/PrembidLocalStorage";
import { sortBy } from 'lodash';
import { Skeleton } from '../library/Skeleton';
import { TabBox } from '../TabBox';
import { ViewDocumentItems } from '../ViewDocumentItems';
import { LotFeatureItems } from '../LotFeatureItems';
import Loading from '../Loading';
import SolidFlagBanner from '@/components/Banners/SoldFlagBanner';
import { useLotBidAndInfoSectionStyles } from './LotBidAndInfoSection.styles';
import ReactReadMoreReadLess from "@/components/ReadMoreReadLess/ReadMoreReadLess";

interface LotBidAndInfoSectionProps {
    auctionId: string;
    lotId: string;
}

const LotBidAndInfoSection: FC<LotBidAndInfoSectionProps> = ({ auctionId, lotId }) => {

    const { classes: { imageContainer, validatingContainer, soldBannerContainer } } = useLotBidAndInfoSectionStyles();

    const { settings } = useSelector((state: RootState) => state.settings);
    const lotClosingValidation = useSelector((state: RootState) => state.lotBidInfoReducer.lotClosingValidation);
    const profileId = useSelector((state: RootState) => state.loginForm.changeProfileId);

    const [activeCarouselTab, setActiveCarouselTab] = useState("0");
    const [documentData, setDocumentData] = useState<any>([]);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
        showProgressSpinner({ description: "Retrieving " + settings.LotName + " Details..." });
    }, [settings?.LotName]);

    const { data: lotData } = useRetrieveLotDetailsPageData(
        {
            queryParams: {
                lotId,
            },
        },
        {
            cacheTime: 0,
            enabled: !!auctionId && !!lotId,
            onError(error) {
                ns.error("", error.message);
            },
            onSettled() {
                hideProgressSpinner();
            }
        }
    );

    const { isFetching: isFetchingAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess } =
        useRetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess(
            {
                queryParams: {
                    relatedTableRecordId: lotId,
                    documentType: DocumentTypeEnum.Lot,
                },
            },
            {
                enabled: !!lotId && !profileId,
                onSuccess(response) {
                    processDocumentData(response);
                },
                onError(error) {
                    ns.error("", error.message);
                },
            }
        );

    const { isFetching: isFetchingAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile } =
        useRetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile(
            {
                queryParams: {
                    relatedTableRecordId: lotId,
                    auctionId: auctionId,
                    documentType: DocumentTypeEnum.Lot,
                    profileId: profileId,
                },
            },
            {
                enabled: !!auctionId && !!lotId && !!profileId,
                onSuccess(response) {
                    processDocumentData(response);
                },
                onError(error) {
                    ns.error("", error.message);
                },
            }
        );

    const lotFeatures = useLotFeatures(lotData?.dataCaptured?.additionalProperties || []);

    const processDocumentData = useCallback(
        (response: Record<string, any>) => {
            const dataArray: { documentUrl: string; documentTemplateName: string | undefined }[] = [];

            Object.values(response).forEach((value) => {
                Object.values(value as Record<string, DocTemplateResultValue>).forEach((docTemplateResultValue) => {
                    const lot = docTemplateResultValue?.documentTemplate?.dataCaptured?.lot;

                    if (lotId && lot?.isApplied && !lot?.isUploadMandatory && docTemplateResultValue?.documents?.length) {
                        docTemplateResultValue?.documents.forEach((docResultValue: DocResultValue) => {
                            const documentUrl = docResultValue?.documentUrl;
                            const documentTemplateName = docTemplateResultValue?.documentTemplate?.name;

                            if (documentUrl) {
                                dataArray.push({ documentUrl, documentTemplateName });
                            }
                        });
                    }
                });
            });

            const sortedDocuments = sortBy(dataArray, ["documentTemplateName", "documentUrl"]);
            setDocumentData(sortedDocuments);
        },
        [lotId]
    );

    const tabItems = useMemo(() => {
        const tabArray: string[] = ["Description"];

        if (lotFeatures?.length > 0) tabArray.push("Features");
        if (documentData?.length > 0) tabArray.push("Documents");

        return tabArray;
    }, [lotFeatures, documentData]);

    const tabData = useMemo(() => {
        const tabArray: any[] = [];

        if (!lotData) {
            tabArray.push(<>
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
            </>
            );
            tabArray.push(<>
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
            </>);
        } else {
            tabArray.push(<h5 style={{ whiteSpace: 'pre-wrap' }}><ReactReadMoreReadLess
                charLimit={500}
                ellipsis={"..."}
                readMoreText={"read more"}
                readLessText={"read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
                readMoreStyle={{ textDecoration: 'underline' }}
                readLessStyle={{ textDecoration: 'underline' }}>
                {lotData?.description || ""}
            </ReactReadMoreReadLess></h5>);

            if (lotFeatures?.length > 0) tabArray.push(<LotFeatureItems features={lotFeatures || []} />);
        }

        if (documentData?.length > 0) {
            tabArray.push(
                <ViewDocumentItems documents={documentData || []} showLoading={(isFetchingAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess || isFetchingAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile)} />
            );
        }

        return tabArray;
    }, [lotData, lotFeatures, documentData, isFetchingAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess, isFetchingAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile]);

    return <>
        <div className="mb-3">
            <div className={imageContainer}>
                {
                    lotData &&
                    lotClosingValidation &&
                    lotClosingValidation?.validating &&
                    lotClosingValidation?.lotId === lotId &&
                    <div className={validatingContainer}>
                        <Loading
                            loading={true}
                            loadingText={lotClosingValidation?.validatingText}
                            errorText={lotClosingValidation?.validatingErrorText}
                            size={80}
                            loadingTextStyle={{ color: 'white' }}
                        />
                    </div>
                }

                {
                    lotData &&
                    lotClosingValidation &&
                    !lotClosingValidation?.validating &&
                    lotClosingValidation?.soldBanner &&
                    lotClosingValidation?.soldBanner?.showBanner &&
                    lotClosingValidation?.lotId === lotId &&
                    <div className={soldBannerContainer}>
                        <SolidFlagBanner backgroundColor="#ff0000" bannerText={lotClosingValidation?.soldBanner?.bannerText} />
                    </div>
                }

                <ImageAndVideoCarousel
                    imageUrls={lotData?.lotImages?.map((image: { imageUrl: string; }) => image.imageUrl) || []}
                    videoUrls={lotData?.lotVideos?.map((video: { videoUrl: string; }) => video.videoUrl) || []}
                    stcStatus={lotData?.stcStatus?.toString()}
                    activeTab={activeCarouselTab}
                    setActiveTab={function (activeTab: string): void {
                        setActiveCarouselTab(activeTab);
                    }}
                    showLoading={!lotData}
                />
            </div>
        </div>

        <div className="mb-3">
            <SectionTitle
                title={lotData?.name}
                lotNumber={lotData?.number}
                showLoading={!lotData} />
        </div>

        <div className="mb-4" >
            <TabBox
                tabItems={tabItems}
                tabData={tabData} />
        </div>

        <GoogleMaps
            center={{
                lat: Number(lotData?.locationLatitude),
                lng: Number(lotData?.locationLongitude)
            }}
            canEdit={false}
            showLabels={false}
            style={{ height: 400 }}
            address={lotData?.locationSearch}
            displayLocation={lotData?.locationVisible}
            showLoading={!lotData} />
    </>
}

export default memo(LotBidAndInfoSection)