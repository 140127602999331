import { FC, MouseEventHandler, memo } from 'react';
import { Card, Skeleton } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from 'react-player/lazy';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useImageAndVideoCarouselStyles } from '../ImageAndVideoCarousel.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/Store';

require("@/assets/css/carousel.css");

interface VideoCarouselProps {
    videos: Array<any>;
    showLoading?: boolean;
}

const VideoCarousel: FC<VideoCarouselProps> = props => {

    const {
        videos,
        showLoading = false
    } = props;

    const { classes: { video, arrows, leftArrow, rightArrow, thumbnail, videoSlide } } = useImageAndVideoCarouselStyles();

    const { settings } = useSelector((state: RootState) => state.settings);

    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

    const renderArrowPrev = (onClickHandler: MouseEventHandler<HTMLButtonElement>, hasPrev: boolean, label: string) =>
        hasPrev && (
            <button
                type="button"
                className={`${arrows} ${leftArrow}`}
                onClick={onClickHandler}
                aria-label={label}>
                <IoIosArrowBack />
            </button>
        );

    const renderArrowNext = (onClickHandler: MouseEventHandler<HTMLButtonElement>, hasNext: boolean, label: string) =>
        hasNext && (
            <button
                type="button"
                className={`${arrows} ${rightArrow}`}
                onClick={onClickHandler}
                aria-label={label}>
                <IoIosArrowForward />
            </button>
        );

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    const customRenderThumb = (children) =>
        children.map((item, idx) => {
            const videoId = youtube_parser(item.props.url);

            return showLoading ? (
                <Skeleton key={idx} animation="wave" variant="rectangular" height={70} width={70} />
            ) : (
                <img
                    key={idx} // Place the key here
                    src={getVideoThumb(videoId)}
                    className={thumbnail}
                    style={{
                        border: '2px solid ' + settings?.Styles?.Primary,
                    }}
                    alt={`video-thumbnail-${idx}`} // Add alt for accessibility
                />
            );
        });


    const YoutubeSlide = ({ url }: { url: string }) => (
        <div className="carousel-wrapper">
            <ReactPlayer width="auto%" height="100%" url={url} controls={true} />
        </div>
    );

    return (
        <Card className={video} raised={true}>
            <Carousel
                thumbWidth={70}
                stopOnHover={true}
                infiniteLoop={true}
                autoPlay={false}
                emulateTouch={true}
                showStatus={false}
                useKeyboardArrows={true}
                showThumbs={true}
                dynamicHeight={false}
                showArrows={true}
                renderItem={customRenderItem}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
                renderThumbs={customRenderThumb}>
                {
                    showLoading ? (
                        Array.from({ length: 10 }).map((_, idx) => (
                            <YoutubeSlide key={idx} url={"https://via.placeholder.com/1928x1240?text=Loading"} />
                        ))
                    ) : (
                        videos.map((videoUrl, idx) => (
                            <YoutubeSlide key={idx} url={videoUrl} />
                        ))
                    )
                }
            </Carousel>
        </Card>
    )
}

export default memo(VideoCarousel)