import { FC, memo } from 'react';

interface SoldFlagComponentProps {
    backgroundColor: string;
    bannerText: string;
    fontSize?: string | number
}

const SoldFlagComponent: FC<SoldFlagComponentProps> = (props) => {
    const { backgroundColor, bannerText, fontSize = '1.75rem' } = props;

    const SoldFlag = () => {
        return (
            <div
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h3 style={{ fontWeight: 'bold', textAlign: 'center', fontSize }} className='text-white'>{bannerText}</h3>
            </div>
        );
    };

    return <SoldFlag />;
};

export default memo(SoldFlagComponent);