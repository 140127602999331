import { combineReducers } from 'redux';

//Authentication
import loginForm from '@/components/LoginForm/store/LoginForm';
import registerCard from '@/components/RegisterCard/store/RegisterCard';
import confirmAccountCard from '@/components/ConfirmAccountCard/store/ConfirmAccountCard';
import forgotPasswordCard from '@/components/ForgotPasswordCard/store/ForgotPasswordCard';
import resetPasswordCard from '@/components/ResetPasswordCard/store/ResetPasswordCard';
import confirmEmailChangeConfirmationCard from '@/components/EmailChangeConfirmationCard/store/EmailChangeConfirmationCard';

//Home
import home from '@/pages/Home/store/Home';

//Auction
import auctions from '@/pages/Auctions/store/Auctions';
import participateButton from '@/components/Buttons/store/Participate';
import bidding from '@/pages/Bids/store/Bidding';

//Lots
import lots from '@/pages/Lots/store/Lots';

//MyActivity
import myActivity from '@/pages/MyActivity/store/MyActivity'

//Documents
import documents from '@/pages/Documents/store/Documents';

//Account
import account from '@/pages/Profile/store/Profile';

//BidProfile
import bidProfile from '@/pages/BiddingProfile/store/BiddingProfile';
import individualProfile from '@/pages/BiddingProfile/store/IndividualProfile';
import companyProfile from '@/pages/BiddingProfile/store/CompanyProfile';
//Theme

import ThemeOptions from './ThemeOptions';

//Settings

import settings from '@/store/Settings';

//Services
import progressSpinner from '@/components/ProgressSpinner/store/ProgressSpinner';
import dialog from '@/components/Dialog/store/Dialog';
import notificationServiceWrapper from '@/components/NotificationServiceWrapper/store/NotificationServiceWrapper';

//Enquiry
import enquiry from '@/components/Enquiry/store/enquiry';

//Notifications Sliding Panel
import notificationPanel from '@/components/NotificationPanel/store/NotificationPanel';

//Contacts
import contacts from '@/components/ContactCard/store/Contacts'

import biddingRegistrationModal from '@/components/RegistrationProcess/store/RegistrationModal';

import { confirmationDialogActivatorReducer } from '../ConfirmationDialogActivatorStore';

import signalR from '@/store/SignalR'

import lotBidInfoReducer from '@/pages/LotBidAndInfo/store/LotBidAndInfo';

export default combineReducers({
    loginForm,
    registerCard,
    confirmAccountCard,
    forgotPasswordCard,
    resetPasswordCard,
    confirmEmailChangeConfirmationCard,

    //Home
    home,

    //Theme
    ThemeOptions,

    //Auctions
    auctions,
    participateButton,
    bidding,

    //Lots
    lots,
    myActivity,

    //Documents
    documents,

    //AccountProfile
    account,

    bidProfile,

    companyProfile,

    individualProfile,

    //Settings
    settings,

    //Services
    progressSpinner,

    dialog,

    notificationServiceWrapper,

    //Enquiry
    enquiry,

    //NotificationPanel
    notificationPanel,

    //Contacts
    contacts,

    biddingRegistrationModal,

    confirmationDialogActivatorReducer,

    signalR,

    lotBidInfoReducer
});