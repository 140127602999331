import { makeStyles } from "tss-react/mui";

export const useLotContactListStyles = makeStyles()((theme) => ({
    container: { minHeight: 100 },
    imageContainer: { flex: 1, minWidth: 130 },
    infoContainer: { flex: 7, padding: 5, paddingLeft: 10 },
    image: { width: '100%', height: '100%', borderRadius: 10 },
    contactHeading: { marginBottom: 15, color: '#000000' },
    contactButtonsContainer: { marginTop: 20 },
    link: { marginRight: 25 },
    buttonIcon: {
        width: 50,
        height: 50,
        padding: 10,
        borderRadius: '50%',
        color: 'white',
        marginBottom: 5,
        background: "#ff0000"
    },
    buttonLabel: { color: "#000000" },
}));
