import React from 'react'
import {
    Popper,
    Paper,
    ClickAwayListener,
} from '@mui/material';
import DocumentsInnerComponent from '../DocumentsInnerComponent/DocumentsInnerComponent';
import { CSSProperties } from '@mui/material/styles/createTypography';

export interface DocumentPopperOptions { }

interface DocumentPopperProps extends DocumentPopperOptions {
    anchorEl: any;
    isBusy: any;
    documents: any;
    popperStyle?: CSSProperties;
    handleDocumentClose: () => void;
    handleViewDocument: (url: string) => void;
}

export const DocumentPopper: React.FC<DocumentPopperProps> = props => {
    const {
        anchorEl,
        isBusy,
        documents: documents,
        popperStyle,
        handleDocumentClose,
        handleViewDocument,
    } = props;

    return (
        <ClickAwayListener onClickAway={() => setTimeout(handleDocumentClose, 5)}>
            <Popper
                style={{ position: 'fixed', ...popperStyle }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal={false}
                popperOptions={{
                    strategy: 'fixed',
                }}
                modifiers={[
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['bottom-start'],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'viewport',
                        },
                    },
                ]}
            >
                <Paper className="popper dropdown-menu-xl overflow-hidden p-0">
                    <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                        <h5 className="text-capitalize pl-1 text-black">Documents</h5>
                    </div>
                    <DocumentsInnerComponent isBusy={isBusy} lotDocuments={documents} handleViewDocument={handleViewDocument} />
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
};

export default DocumentPopper;