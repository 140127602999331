import { FC, ReactNode, memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/Store";
import { Skeleton } from "@mui/material";

export interface SectionTitleProps {
    title?: string;
    subTitle?: string | ReactNode;
    lotNumber?: string;
    showLoading?: boolean;
}

const SectionTitle: FC<SectionTitleProps> = ({
    title,
    subTitle,
    lotNumber,
    showLoading = false
}) => {

    const { settings } = useSelector((state: RootState) => state.settings);

    return <>
        {
            showLoading ? (
                <Skeleton animation="wave" variant="text" component="h3" width="100%" />
            ): (
                    <>
                        {
                            title &&
                            <h3 style={{
                                color: settings?.Styles?.OverridePageTextColor
                                    ? settings?.Styles?.OverridePageTextColor
                                    : "#000000",
                            }}>
                                {
                                    lotNumber && <span
                                        className="text-black-75 font-weight-bold"
                                        style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#2b2b2b" }}
                                    >
                                        {lotNumber + " | "}
                                    </span>
                                }
                                <span
                                    className="text-black font-weight-bold"
                                    style={{
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        color: settings?.Styles?.OverridePageTextColor
                                            ? settings?.Styles?.OverridePageTextColor
                                            : "#000000",
                                    }}
                                >
                                    {title}
                                </span>
                            </h3>
                        }

                        {
                            subTitle &&
                            <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>{subTitle}</h4>
                        }
                    </>
            )
        }
    </>
};

export default memo(SectionTitle);
