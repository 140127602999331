import React, { useMemo, useEffect, useState, memo, FC, useReducer } from "react";
import { zeroPad } from "react-countdown";
import { Countdown } from "@/components/library/Countdown";
import {
    Button,
    Card,
    Grid,
    List,
    ListItem,
    Tooltip,
} from "@mui/material";
import LotDetailCardDocumentSelector from "@/components/Cards/LotDetailCardDocumentSelector";
import { AuctionTypeEnum } from "@/helpers/AuctionTypeEnum";
import MyActivityWishlist from "../MyActivity/MyActivityWishlist";
import PopperComponent from "@/components/Popper/PopperComponent";
import { FaInfoCircle } from "react-icons/fa";
import { AuctionLotStatusEnum } from "@/helpers/AuctionLotStatusEnum";
import { connect, useSelector } from "react-redux";
import SolidFlagBanner from "@/components/Banners/SoldFlagBanner";
import { PricingDisplay } from "@/components/library/PricingDisplay";
import { getHighestBidColour, getHighestBidSuffix, insertUrlParam } from '@/helpers/Utils';
import { useConfigureSoldBanner } from "@/helpers/hooks/useConfigureSoldBanner";
import useHistory from "../../helpers/hooks/useHistory";
import { RootState } from "../../store/Store";
import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers";
import { onReceiveMessage, clearOnReceiveMessage } from "prembid-shared-library-npm/signalr";
import { MessageSubject } from "prembid-shared-library-npm/types";
import { useFinalizing, useConfigureCounter } from "prembid-shared-library-npm/hooks";
import Loading from "../Loading";
import { LotStatus } from "../../helpers/LotStatusEnum";
import { BiddingTable } from "../BiddingTable";

export interface LotDetailCardProps {
    imgSrc: string;
    displayFullLengthImage: boolean;
    header: string;
    description?: string;
    lotFeatures?: any;
    auctionId: string;
    lotId: string;
    commission: number;
    lotNumber: string;
    auctionType: number;

    onRefresh?: () => void;

    startDateTimeAt?: any;
    endDateTimeAt?: any;

    successBadgeText?: string;
    startingPrice?: number;
    dangerBadgeText?: string;
    guidePrice?: any;

    hasExtensions: boolean;
    isActive: boolean;
    documentCount: any;

    registrationStatus: any;
    wishListStatus: boolean;
    overrideGuidePriceRulesAndAlwaysHide: boolean;
    overrideShowBidButtonForCompletedLots: boolean;
    settings: any;
    showReserve: boolean;
    highestBid: number;
    highestBidProfileId: string | null;
    extendedBy: number;
    lot: any
}

const LotDetailCard: FC<LotDetailCardProps> = (props) => {

    const {
        imgSrc,
        displayFullLengthImage,
        header,
        description,
        lotFeatures,
        auctionId,
        lotId,
        commission,
        lotNumber,
        auctionType,
        startDateTimeAt,
        endDateTimeAt,
        successBadgeText,
        guidePrice,
        hasExtensions,
        isActive,
        documentCount,
        registrationStatus,
        wishListStatus,
        overrideGuidePriceRulesAndAlwaysHide,
        overrideShowBidButtonForCompletedLots,
        settings,
        showReserve,
        highestBid,
        highestBidProfileId,
        extendedBy,
        lot,
        startingPrice
    } = props;

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const history = useHistory();

    const now = getNewDateWithOffset(currentDateTimeOffset);
    const start = useMemo(() => new Date(startDateTimeAt), [startDateTimeAt]);
    const [aspectRatio, setAspectRatio] = useState(0);

    const [infoAnchorEl, setInfoAnchorEl] = useState(null);
    const [quickBidAnchorEl, setQuickBidAnchorEl] = useState(null);

    const [end, setEnd] = useState<Date>();

    const reserveMet = (reservePrice: number, highestBid: number) => {
        const tempReservePrice = reservePrice ? Number(reservePrice) : 0;
        return tempReservePrice > 0 ? Number(highestBid) >= tempReservePrice : true;
    };

    const { finalizingText, setValidating, validating } = useFinalizing({
        auctionType,
        lotLocale: settings.LotName,
    });

    const [liveUpdates, setLiveUpdates] = useState({
        highestBid: highestBid,
        highestBidProfileId: lot?.winningProfileId,
        isExtended: hasExtensions,
        reserveMet: reserveMet(lot?.reservePrice, lot?.overrideWinningBidAmount ?? lot?.winningRegisteredProfileAmount),
        reservePrice: lot?.reservePrice,
        startingPrice,
        extendedBy: extendedBy,
    });

    useEffect(() => {
        setLiveUpdates((current) => ({
            ...current,
            highestBid: highestBid,
            highestBidProfileId: lot?.winningProfileId,
            reserveMet: reserveMet(lot?.reservePrice, lot?.overrideWinningBidAmount ?? lot?.winningRegisteredProfileAmount),
            isExtended: hasExtensions,
            reservePrice: lot?.reservePrice,
            startingPrice,
            extendedBy: extendedBy,
        }));
    }, [hasExtensions, highestBid, lot?.winningProfileId, highestBidProfileId, lot?.overrideWinningBidAmount, lot?.reservePrice, lot?.winningRegisteredProfileAmount, startingPrice, extendedBy])

    useEffect(() => {
        if (!endDateTimeAt) return;
        const endTime = new Date(endDateTimeAt)
        if (liveUpdates?.extendedBy) endTime.setTime(endTime.getTime() + ((liveUpdates.extendedBy ?? extendedBy ?? 0) * 1000))
        setEnd(new Date(endTime));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDateTimeAt, liveUpdates?.extendedBy]);

    const { countdownDate, countdownHeader, status, previousStatus } = useConfigureCounter({
        start,
        end,
    });

    useEffect(() => {
        if (previousStatus !== undefined && status === LotStatus.COMPLETED && !lot?.sold) {
            setValidating(true);
            setTimeout(() => {
                setValidating(false)
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const { soldBanner, handleShowSoldBanner } = useConfigureSoldBanner(
        lot,
        liveUpdates
    );

    useEffect(() => {
        handleShowSoldBanner(lot, liveUpdates, status);
    }, [handleShowSoldBanner, liveUpdates, lot, status]);

    useEffect(() => {
        const callbackId = onReceiveMessage((message: string) => {
            if (message) {
                var payload = JSON.parse(message);

                if (payload?.data) {
                    const messageSubject = payload.data['messageSubject'];
                    const messageAuctionId = payload.data['auctionId'];
                    const messageLotId = payload.data['lotId'];
                    const messageAmount = payload.data['amount'];
                    const messageProfileId = payload.data['profileId'];
                    const messageExtendedBy = payload.data['extendedBy'];

                    if (messageLotId === lotId) {
                        if (messageSubject === MessageSubject.BidUpdated) {
                            const winningRegisteredProfileAmount = payload.data['winningRegisteredProfileAmount'];
                            const winningProfileId = payload.data['winningProfileId'];

                            setLiveUpdates(current => ({
                                ...current,
                                highestBid: winningRegisteredProfileAmount,
                                highestBidProfileId: winningProfileId,
                                reserveMet: reserveMet(current?.reservePrice, winningRegisteredProfileAmount),
                            }));
                        }
                    }

                    if (messageAuctionId === auctionId && messageLotId === lotId) {
                        if (messageSubject === MessageSubject.CreateBid) {
                            setLiveUpdates(current => ({
                                ...current,
                                highestBid: messageAmount,
                                highestBidProfileId: messageProfileId,
                                reserveMet: reserveMet(current?.reservePrice, messageAmount),
                            }));

                            if (messageExtendedBy !== "" && messageExtendedBy !== undefined) {
                                setLiveUpdates(current => ({
                                    ...current,
                                    isExtended: true,
                                    extendedBy: messageExtendedBy,
                                }));
                            }
                        }

                        if (messageSubject === MessageSubject.UpdatedStartPrice) {
                            setLiveUpdates((current) => ({
                                ...current,
                                startingPrice: payload.data['startingPrice'],
                            }));
                        }

                        if (messageSubject === MessageSubject.UpdatedReservePrice) {
                            setLiveUpdates((current) => ({
                                ...current,
                                reservePrice: Number(payload.data["reservePrice"]),
                                reserveMet: reserveMet(payload.data["reservePrice"], current?.highestBid),
                            }));
                        }

                        if (messageSubject === MessageSubject.ManuallyExtendedLot) {
                            setLiveUpdates((current) => ({
                                ...current,
                                isExtended: true,
                                extendedBy: messageExtendedBy,
                            }));
                        }
                    }
                }
            }
        });
        return () => clearOnReceiveMessage(callbackId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const recordScrollPosition = () => {
        var top = window.scrollY;
        insertUrlParam("top", top.toString());
    };

    const btnViewLotDetailsRoute = () => {
        recordScrollPosition();
        history.push("/LotBidAndInfo/" + auctionId + "/" + lotId);
    };

    const btnBidRoute = () => {
        recordScrollPosition();
        history.push("/LotBidAndInfo/" + auctionId + "/" + lotId);
    };

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <div>
                <h5>
                    <span>
                        {zeroPad(days)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        dd:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(hours)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        hh:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(minutes)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        mm:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(seconds)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        ss
                    </span>
                </h5>
            </div>
        );
    };


    const CountdownTimerComponent = () => {
        if (!!end && now > end) {
            return <></>;
        } else {
            return (
                <>
                    <div
                        className="z-over p-1"
                        style={{
                            zIndex: 2,
                            position: "absolute",
                            background: "#00000070",
                            left: "10px",
                            marginTop: "10px",
                            width: "200px",
                            borderRadius: "5px",
                        }}
                    >
                        <div className="ml-1">
                            <small className="text-white">{countdownHeader}</small>
                            <small className="text-white">
                                {countdownDate && <Countdown
                                    date={countdownDate}
                                    now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
                                    renderer={CountdownRenderer}
                                />}
                            </small>
                        </div>
                    </div>
                </>
            );
        }
    };

    const getSTCStatus = (paramStcStatus) => {
        if (paramStcStatus?.toString() === "0") {
            return "STC No";
        } else if (paramStcStatus?.toString() === "1") {
            return "STC Yes";
        } else if (paramStcStatus?.toString() === "2") {
            return "STC Awaiting";
        }
    };

    const GetReserveMetStatus = () => {
        if (!showReserve) {
            return <></>;
        }

        if (liveUpdates?.reserveMet) {
            return (
                <div
                    className="badge badge-pill mt-2 ml-1 text-capitalize"
                    style={{
                        fontSize: "10x",
                        background: "#ffffff",
                        color: "#1bc943",
                        boxShadow: "0 0 0 2px #1bc943",
                    }}
                >
                    Reserve Met
                </div>
            );
        } else {
            return (
                <div
                    className="badge badge-pill mt-2 ml-1 text-capitalize"
                    style={{
                        fontSize: "10x",
                        background: "#ffffff",
                        color: "#f83245",
                        boxShadow: "0 0 0 2px #f83245",
                    }}
                >
                    Reserve Not Met
                </div>
            );
        }
    };

    const handleInfoPopperClick = (event) => {
        setInfoAnchorEl(event.currentTarget);
    };

    const handleInfoPopperClose = () => {
        setInfoAnchorEl(null);
    };

    const handleQuickBidPopperClick = (event) => {
        setQuickBidAnchorEl(event.currentTarget);
    };

    const handleQuickBidPopperClose = () => {
        setQuickBidAnchorEl(null);
    };

    const getInfoElement = () => {
        const placeholderText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tender" : "bid";
        const placeholderActioningText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tendering" : "bidding";

        let lotStatus = AuctionLotStatusEnum["Unavailable"];

        if (!isActive) lotStatus = AuctionLotStatusEnum["Unavailable"];

        if (now < start) lotStatus = AuctionLotStatusEnum.Awaiting;

        if (!!end && now > end) lotStatus = AuctionLotStatusEnum.Completed;

        if (!!end && start < now && now < end) {
            if (liveUpdates?.isExtended) {
                lotStatus = AuctionLotStatusEnum.Extended;
            } else {
                lotStatus = AuctionLotStatusEnum.Open;
            }
        }

        if (validating) lotStatus = AuctionLotStatusEnum.Validating;

        return (
            <List>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        {lotStatus !== undefined && <>{AuctionLotStatusEnum[lotStatus.toString()]} -{" "}</>}
                        {lotStatus === AuctionLotStatusEnum["Unavailable"] && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.LotName.toLowerCase() +
                                    " is no longer available and " +
                                    placeholderText +
                                    "s cannot be submitted on it"}
                            </span>
                        )}
                        {lotStatus === AuctionLotStatusEnum.Awaiting && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.LotName.toLowerCase() +
                                    " hasn't opened yet, but you need to register if you want to " +
                                    placeholderText +
                                    " when it does"}
                            </span>
                        )}
                        {lotStatus === AuctionLotStatusEnum.Open && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.LotName.toLowerCase() +
                                    " is open for you to " +
                                    placeholderText +
                                    " on " +
                                    "if you have registered and been approved"}
                            </span>
                        )}
                        {lotStatus === AuctionLotStatusEnum.Extended && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.LotName.toLowerCase() +
                                    " has been extended due to " +
                                    placeholderActioningText +
                                    " activity, " +
                                    placeholderActioningText +
                                    " is still open on certain " +
                                    settings.LotName.toLowerCase() +
                                    "s"}
                            </span>
                        )}
                        {lotStatus === AuctionLotStatusEnum.Completed && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.LotName.toLowerCase() +
                                    " has completed and " +
                                    placeholderActioningText +
                                    " is closed"}
                            </span>
                        )}
                        {lotStatus === AuctionLotStatusEnum.Validating && (
                            <span className="text-black font-weight-normal">
                                {"Validating the status of the " + settings.LotName.toLowerCase()}
                            </span>
                        )}
                    </h5>
                </ListItem>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        STC -{" "}
                        <span className="text-black font-weight-normal">
                            {"Subject to Confirmation, conditions need to be met in order for the " +
                                settings.LotName.toLowerCase() +
                                " to be sold"}
                        </span>
                    </h5>
                </ListItem>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        Buyers Commission -{" "}
                        <span className="text-black font-weight-normal">
                            {"Commission payable by the buyer on top of the purchase price"}
                        </span>
                    </h5>
                </ListItem>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        Reserve -{" "}
                        <span className="text-black font-weight-normal">
                            {"Has the minimum price for the " +
                                settings.LotName.toLowerCase() +
                                " been met?"}
                        </span>
                    </h5>
                </ListItem>
            </List>
        );
    };

    useEffect(() => {
        function getImageDimensions(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = url;
            });
        }

        getImageDimensions(imgSrc)
            .then((res: any) => {
                setAspectRatio(res.width / res.height);
            })

    }, [imgSrc])

    return (
        <Grid item lg={12}>
            <Card className="overflow-visible" raised={true}>
                <div className="d-flex flex-xl-row flex-column align-items-center">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={0}
                    >
                        <Grid item sm={4}>
                            <Card className="card-img-top img-fit-container" style={{
                                maxHeight: "232px",
                                aspectRatio: "1.78",
                                background: '#000',
                                boxShadow: '0 0 2px #FFFFFF',
                            }}>
                                {!validating && <CountdownTimerComponent />}

                                {
                                    validating &&
                                    <div style={{ position: 'absolute', width: '100%', top: 'calc(50% - 30px)', zIndex: 10 }}>
                                        <Loading loading={true} loadingText={finalizingText} size={28} loadingTextStyle={{ fontSize: 12, color: 'white' }} />
                                    </div>
                                }

                                {!validating && soldBanner && soldBanner.showBanner === true && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            top: displayFullLengthImage ? "45%" : "40%",
                                        }}
                                    >
                                        <SolidFlagBanner
                                            backgroundColor="#ff0000"
                                            bannerText={soldBanner?.bannerText}
                                        />
                                    </div>
                                )}

                                <img
                                    src={imgSrc}
                                    alt="Missing"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        cursor: "pointer",
                                        objectFit: 'scale-down',
                                        opacity: validating ? 0.3 : 1
                                    }}
                                    onClick={btnViewLotDetailsRoute}
                                />
                            </Card>
                        </Grid>
                        <Grid item sm={8} className="pl-2 pr-2">
                            <div className="pl-0 pl-xl-2 py-0 py-xl-2 text-center text-md-left">
                                <div className="mb-4">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        spacing={0}
                                    >
                                        <Grid item sm={9}>
                                            {!isActive ? (
                                                <div
                                                    className="badge badge-pill badge-dark mt-2 text-capitalize "
                                                    style={{ fontSize: "10px" }}
                                                >
                                                    Unavailable
                                                </div>
                                            ) : (
                                                <>
                                                    {validating && <>
                                                        <div
                                                            className="badge badge-pill badge-dark mt-2 text-capitalize"
                                                            style={{ fontSize: "10px" }}
                                                        >
                                                            Validating
                                                        </div>
                                                    </>}
                                                    {!validating && <>
                                                        {now < start && (
                                                            <div
                                                                className="badge badge-pill badge-dark mt-2 text-capitalize"
                                                                style={{ fontSize: "10px" }}
                                                            >
                                                                Awaiting
                                                            </div>
                                                        )}
                                                        {!!end && start < now && now < end && liveUpdates?.isExtended && (
                                                            <div
                                                                className="badge badge-pill badge-warning mt-2 text-capitalize "
                                                                style={{ fontSize: "10px" }}
                                                            >
                                                                Extended
                                                            </div>
                                                        )}
                                                        {!!end && start < now && now < end && !liveUpdates?.isExtended && (
                                                            <div
                                                                className="badge badge-pill badge-success mt-2 text-capitalize "
                                                                style={{ fontSize: "10px" }}
                                                            >
                                                                Open
                                                            </div>
                                                        )}
                                                        {!!end && now > end && (
                                                            <div
                                                                className="badge badge-pill badge-danger mt-2 text-capitalize"
                                                                style={{ fontSize: "10px" }}
                                                            >
                                                                Completed
                                                            </div>
                                                        )}
                                                    </>}
                                                </>
                                            )}
                                            <div
                                                className="badge badge-pill badge-dark mt-2 ml-1 text-capitalize"
                                                style={{ background: "#2B2B2B", fontSize: "10px" }}
                                            >
                                                {getSTCStatus(successBadgeText)}
                                            </div>
                                            <div
                                                className="badge badge-pill badge-primary mt-2 ml-1 text-capitalize "
                                                style={{ fontSize: "10px" }}
                                            >
                                                {"Buyer's Commission " + commission + "%"}
                                            </div>

                                            {!!end && isActive && now < end && <GetReserveMetStatus />}

                                            <Tooltip arrow title="Click for Info" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    size="large"
                                                    className="btn-white d-30 btn-pill p-0 btn-icon btn-animated-icon align-items-right"
                                                    onClick={handleInfoPopperClick}
                                                >
                                                    {infoAnchorEl && (
                                                        <PopperComponent
                                                            headerText={"Info"}
                                                            anchorEl={infoAnchorEl}
                                                            element={getInfoElement()}
                                                            handlePopperComponentClose={handleInfoPopperClose}
                                                        />
                                                    )}
                                                    <FaInfoCircle style={{ color: '#2b2b2b' }} color={'#2b2b2b'} />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item sm={3}>
                                            {isActive && (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    spacing={0}
                                                >
                                                    <Grid item sm={10} className={"text-md-right"}>
                                                        <LotDetailCardDocumentSelector
                                                            auctionId={auctionId}
                                                            lotId={lotId}
                                                            documentCount={documentCount}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={2}>
                                                        <MyActivityWishlist
                                                            lotId={lotId}
                                                            isOnWishlist={wishListStatus}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Grid item sm={displayFullLengthImage ? 6 : 5}>
                                                <h5 className="mt-4">
                                                    <span
                                                        className="text-black-75 font-weight-bold"
                                                        style={{ color: "#5a5a5a" }}
                                                    >
                                                        {lotNumber + " | "}
                                                    </span>
                                                    <span
                                                        className="text-black font-weight-bold"
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {header}
                                                    </span>
                                                </h5>
                                            </Grid>

                                            <Grid item sm={displayFullLengthImage ? 6 : 7}>
                                                {isActive && (
                                                    <div className="mt-4">
                                                        <PricingDisplay
                                                            auctionType={auctionType}
                                                            guidePrice={guidePrice?.toString()}
                                                            overrideGuidePriceRulesAndAlwaysHide={
                                                                overrideGuidePriceRulesAndAlwaysHide
                                                            }
                                                            registrationStatus={registrationStatus}
                                                            openingBid={liveUpdates?.startingPrice ?? 0}
                                                            highestBid={liveUpdates?.highestBid}
                                                            containerStyle={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "100%",
                                                                height: "auto",
                                                                padding: 8,
                                                            }}
                                                            fontSize={14}
                                                            highestBidColour={getHighestBidColour(liveUpdates?.highestBidProfileId)}
                                                            highestBidSuffix={getHighestBidSuffix(liveUpdates?.highestBidProfileId)}
                                                        />
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                sm={displayFullLengthImage ? 6 : 5}
                                                className="mt-4"
                                            >
                                                <h6
                                                    className="text-black-75 "
                                                    style={{
                                                        color: "#5a5a5a",
                                                        whiteSpace: "pre-wrap",
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    {description && description?.length > 100
                                                        ? description?.substring(0, 100) + "..."
                                                        : description}
                                                </h6>
                                            </Grid>
                                            <Grid
                                                item
                                                sm={displayFullLengthImage ? 6 : 7}
                                                className="mt-4"
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    {lotFeatures.map((lotFeature, idx) => {
                                                        if (idx <= 3) {
                                                            return (
                                                                <>
                                                                    <Grid item xs={6} key={"lotF-" + idx}>
                                                                        <h6 className=" mb-2 text-black">
                                                                            {lotFeature.key}
                                                                        </h6>
                                                                        <h6
                                                                            className="text-black-75 mb-2"
                                                                            style={{ color: "#5a5a5a" }}
                                                                        >
                                                                            {lotFeature.value}
                                                                        </h6>
                                                                    </Grid>
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="mb-4 mb-xl-0">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}>
                                        <Grid item sm={4}>
                                            {isActive && (
                                                <Button
                                                    href=""
                                                    fullWidth
                                                    onClick={btnViewLotDetailsRoute}
                                                    className="btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize">
                                                    <span className="btn-wrapper--label">{Number(auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Tender/Info" : "Bid/Info"}</span>
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item sm={4}>
                                            {isActive && Number(auctionType) !== Number(AuctionTypeEnum.Streamed.toString()) && <>
                                                <Button
                                                    href=""
                                                    fullWidth
                                                    style={{ background: '#2b2b2b' }}
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    size="large"
                                                    className="btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"
                                                    onClick={handleQuickBidPopperClick}>
                                                    {quickBidAnchorEl && (
                                                        <PopperComponent
                                                            popperStyle={{ minWidth: 400 }}
                                                            headerText={""}
                                                            anchorEl={quickBidAnchorEl}
                                                            element={<BiddingTable auctionId={auctionId} lotId={lotId} showCompactView={true} />}
                                                            handlePopperComponentClose={handleQuickBidPopperClose}
                                                        />
                                                    )}
                                                    <span className="btn-wrapper--label">{Number(auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Quick Tender" : "Quick Bid"}</span>
                                                </Button>
                                            </>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </Grid>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(LotDetailCard));
