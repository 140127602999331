import { FC, ReactNode, memo, useState } from "react";
import { Card } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTabBoxStyles } from "./TabBox.styles";
import TabBoxItem from "./sub-components/TabBoxItem";

export interface TabBoxProps {
    tabItems: string[];
    tabData: (ReactNode)[];
}

const TabBox: FC<TabBoxProps> = ({ tabItems, tabData }) => {

    const defaultValue = tabItems[0];
    const [value, setValue] = useState(defaultValue);
    const { classes } = useTabBoxStyles();

    const handleTabChange = (_event?: React.SyntheticEvent, selectedValue?: string) => {
        setValue(selectedValue ?? defaultValue);
    };

    return (
        <Card raised={true}>
            <TabContext value={value}>
                <TabList onChange={handleTabChange}
                    classes={{
                        indicator: classes.tabListIndicator,
                    }}>
                    {tabItems.map((tabItem) => {
                        return (
                            <TabBoxItem key={tabItem} value={tabItem} label={tabItem} onClick={() => handleTabChange(undefined, tabItem)} />
                        )
                    })}
                </TabList>
                {
                    tabData.map((panelObject, panelIndex) => (
                        <TabPanel
                            key={tabItems[panelIndex]}
                            value={tabItems[panelIndex]}>
                            {panelObject}
                        </TabPanel>
                    ))}
            </TabContext>
        </Card>
    );
};

export default memo(TabBox);