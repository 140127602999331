import React, { memo } from 'react';
import {
    Popper,
    Paper,
    ClickAwayListener,
} from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';

export interface PopperComponentOptions { }

interface PopperComponentProps extends PopperComponentOptions {
    anchorEl: any;
    headerText?: string;
    element?: React.ReactNode;
    popperStyle?: CSSProperties;
    handlePopperComponentClose: () => void;
}

export const PopperComponent: React.FC<PopperComponentProps> = props => {
    const {
        anchorEl,
        headerText,
        element,
        popperStyle,
        handlePopperComponentClose,
    } = props;

    return (
        <ClickAwayListener onClickAway={() => setTimeout(handlePopperComponentClose, 5)}>
            <Popper
                style={{ position: 'fixed', ...popperStyle }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal={false}
                popperOptions={{
                    strategy: 'fixed',
                }}
                modifiers={[
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['bottom-start'],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'viewport',
                        },
                    },
                ]}
            >
                <Paper className="popper dropdown-menu-xl overflow-hidden p-0">
                    {headerText && (
                        <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                            <h5 className="text-capitalize pl-1 text-black">{headerText}</h5>
                        </div>
                    )}
                    {element}
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
};

export default memo(PopperComponent);