import { FC, memo } from 'react';
import CurrencyLabel from '../../CurrencyComponent/CurrencyLabel';
import { Skeleton } from '../../library/Skeleton';
import { useBiddingTableStyles } from '../BiddingTable.styles';

type MinimumIncrementProps = {
    showLoading?: boolean;
    minimumIncrement: number;
}

const MinimumIncrement: FC<MinimumIncrementProps> = ({ showLoading = false, minimumIncrement }) => {

    const { classes: { leftRightTextContainer } } = useBiddingTableStyles();

    return <>
        {
            showLoading ? (
                <div className={leftRightTextContainer}>
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                </div>
            ) : (
                <div className={leftRightTextContainer}>
                    <h5 className="text-black text-left">Minimum Increment</h5>
                    <h5 className="text-black text-right"><CurrencyLabel value={minimumIncrement} /></h5>
                </div>
            )
        }
    </>
}

export default memo(MinimumIncrement);