import { LotClosingValidation, LotContact } from "prembid-shared-library-npm/types";

const SET_LOT_CLOSING_VALIDATION = "SET_LOT_CLOSING_VALIDATION";
const SET_LOT_CONTACT = "SET_LOT_CONTACT";

const initialState = {
    lotClosingValidation: {
        lotId: "",
        validating: false,
        validatingText: "",
        validatingErrorText: "",
        soldBanner: {
            showBanner: false,
            bannerText: ""
        }
    } as LotClosingValidation,
    lotContact: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
    } as LotContact
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_LOT_CLOSING_VALIDATION: return {
            ...state,
            lotClosingValidation: action.payload,
        };
        case SET_LOT_CONTACT: return {
            ...state,
            lotContact: action.payload,
        };
        default: return state
    }
}

export const setLotClosingValidating = (payload: LotClosingValidation) => {
    return { type: SET_LOT_CLOSING_VALIDATION, payload: payload }
}

export const setLotContact = (payload: LotContact) => {
    return { type: SET_LOT_CONTACT, payload: payload }
}