import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { deleteNotification, refreshLotNotificationsList, refreshNotificationsList, setAllNotificationsAsRead, setNotificationAsRead, togglePanel } from './store/NotificationPanel'
import { Avatar, Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, Menu, MenuItem, Paper, Badge, Tooltip, Drawer } from '@mui/material';
import { makeStyles, withStyles } from "tss-react/mui";
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage';
import {
    MailOutlined,
    DraftsOutlined,
    MoreVert as MoreVertIcon,
    Launch as LaunchIcon
} from '@mui/icons-material'
import { grey, red } from '@mui/material/colors';
import ns from '@/helpers/NotificationService';
import { FaRedo } from "react-icons/fa";
import clsx from 'clsx';
import { formatDateTime } from '../../helpers/Utils';
import useHistory from '../../helpers/hooks/useHistory';

const StyledBadge = withStyles(Badge, {
    badge: {
        top: "12px",
        right: "15px",
        backgroundColor: "var(--danger)",
        color: "var(--danger)",
        boxShadow: "0 0 0 2px #fff",
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    }
});

const useStyles = makeStyles()((theme) => ({
    read: {
        backgroundColor: grey[300],
    },
    unread: {
        backgroundColor: red[400],
    },
    cardHeader: {
    },
    drawer: {

    },
    tabsContainer: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    placeHolder: {
        width: 50,
        height: 50,
        color: grey[300]
    },
    activeNotificationTitle: {
        maxWidth: 200
    },
    activeNotificationCard: {
        maxWidth: 345,
    },
    activeNotificationImage: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    activeNotificationExpand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    activeNotificationExpandOpen: {
        transform: 'rotate(180deg)',
    }
}));

const LimitedBackdrop = withStyles(Backdrop, {
    root: {
        position: "fixed",
        zIndex: 1
    }
});

interface NotificationPanelProps {
    open: boolean,
    list: any[],
    activeLotList: any[],
    unreadNotificationCount: number,
    generalUnreadCount: number,
    pagination: any,
    loading: boolean,
    lastRefreshed: string | undefined,
    newMessages: boolean,
    togglePanel_d: (status) => void,
    settings: any,
    refreshNotifications_d: (profileId: string, pagination: any, includeRead: boolean, onCompletedCallback: (response) => void, withReplacement?: boolean) => void,
    refreshLotNotifications_d: (profileId: string, lotId: string | undefined, pagination: any, includeRead: boolean, onCompletedCallback: (response) => void) => void,
    setNotificationAsRead_d: (notificationId: string, onCompletedCallback: (response: any) => void) => void,
    setAllNotificationsAsRead_d: (profileId: string, onCompletedCallback: (response: any) => void) => void,
    deleteNotification_d: (notificationId: string, onCompletedCallback: (response: any) => void) => void
}

const NotificationPanel: React.FC<NotificationPanelProps> = props => {
    const { open,
        list,
        activeLotList,
        pagination,
        unreadNotificationCount,
        generalUnreadCount,
        loading,
        lastRefreshed,
        newMessages,
        togglePanel_d,
        settings,
        refreshNotifications_d,
        refreshLotNotifications_d,
        setNotificationAsRead_d,
        setAllNotificationsAsRead_d,
        deleteNotification_d
    } = props;

    const TabNames = {
        Lots: `${settings.LotName}s`,
        History: 'All'
    }

    const [selectedTab, setSelectedTab] = useState<any>(TabNames.Lots);
    const { classes } = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setOpenMenu] = useState(undefined);

    const history = useHistory();

    useEffect(() => {
        if (open) {
            handleRefresh();
        }
    }, [open])

    const handleClose = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        togglePanel_d(false);
    };

    const handleMenu = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(id);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenu(undefined);
    };

    const handleViewLot = (path) => {
        handleMenuClose();
        togglePanel_d(false);
        let timestamp = Date.now().valueOf();
        history.push(`${path}?ts=${timestamp}`);
    };

    const handleViewAuction = (path) => {
        handleMenuClose();
        togglePanel_d(false);
        let timestamp = Date.now().valueOf();
        history.push(`${path}?ts=${timestamp}`);
    };

    const handleSetAllNotificationsAsRead = () => {
        let profileId = PrembidLocalStorage.currentProfile;
        if (profileId && profileId !== '') {
            setAllNotificationsAsRead_d(profileId, (response) => {
                handleRefresh();
            });
        }
    }

    const handleSetNotificationAsRead = (notificationId) => {
        if (notificationId && notificationId !== '') {
            handleMenuClose();
            setNotificationAsRead_d(notificationId, (response) => {
                if (response.success === false)
                    ns.error('', "Failed to to mark the notification as read");
            });
        }
    }

    const handleDeleteNotification = (notificationId) => {
        if (notificationId && notificationId !== '') {
            handleMenuClose();
            deleteNotification_d(notificationId, (response) => {
                if (response.success === false)
                    ns.error('', "Failed to the delete the notification");
            });
        }
    }

    const handleLoadMoreNotifications = () => {
        let profileId = PrembidLocalStorage.currentProfile;
        if (profileId && profileId !== '') {
            let tempPagination = { ...pagination };
            tempPagination.currentPage = pagination.currentPage + 1;
            refreshNotifications_d(profileId, tempPagination, true, (response) => {
                //TODO: Handle response if needed
            }, false);
        }
    }


    const renderHistoricNotification = (item, showViewLotInMenu: boolean = true, showViewAuctionInMenu: boolean = true): JSX.Element => <ListItem alignItems="flex-start" key={item.id} style={{ width: '100%', padding: 0 }}>
        <Card style={{ width: '100%', padding: 0 }} className='card-transparent' raised={true}>
            <CardHeader
                style={{ padding: '0px', paddingTop: '10px', paddingRight: '10px' }}
                avatar={<Avatar className={item.isRead ? classes.read : classes.unread}>
                    {item.isRead ? <DraftsOutlined /> : <MailOutlined />}
                </Avatar>}
                action={
                    <>
                        <IconButton onClick={(event) => handleMenu(event, item.id)} style={{ transform: 'translateY(5px)' }}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={menuOpen === item.id}
                            onClose={handleMenuClose}
                        >
                            {!item.isRead && <MenuItem onClick={() => handleSetNotificationAsRead(item.id)}>Mark as Read</MenuItem>}
                            {showViewAuctionInMenu && item.jsonData?.auctionId && <MenuItem onClick={() => handleViewAuction(`/Lots/${item.jsonData?.auctionId}`)}>{"View " + settings.AuctionName}</MenuItem>}
                            {showViewLotInMenu && item.jsonData?.lotBiddingLink && <MenuItem onClick={() => handleViewLot(item.jsonData?.lotBiddingLink)}>{"View " + settings.LotName}</MenuItem>}
                            <MenuItem onClick={() => handleDeleteNotification(item.id)}>Delete</MenuItem>
                        </Menu>
                    </>
                }
                title={item.jsonData.title}
                subheader={item.jsonData.message}
            />
            <CardContent style={{ padding: 0 }}>
                <div style={{ float: 'right' }}>
                    <small style={{ opacity: 0.5, paddingRight: '26px' }}>{formatDateTime(item.createdAt, "yyyy/MM/dd HH:mm")}</small>
                </div>
            </CardContent>
        </Card>
    </ListItem>

    const renderActiveLotNotification = (item): JSX.Element => {
        const firstNotification = item.notifications[0];

        return <ListItem alignItems="flex-start" key={item.id} style={{ width: '100%', padding: 0 }}>
            <Card style={{ width: '100%', padding: 0 }} className='card-transparent'>
                <CardHeader
                    style={{ padding: '0px', paddingTop: '10px', paddingRight: '10px' }}
                    avatar={<Avatar src={item.imageUrl} >
                        N
                    </Avatar>}
                    action={
                        <IconButton onClick={() => handleViewLot(firstNotification.jsonData?.lotBiddingLink)}
                            style={{ transform: 'translateY(5px)' }}>
                            <LaunchIcon />
                        </IconButton>
                    }
                    title={item.name}
                    classes={{
                        title: classes.activeNotificationTitle
                    }}
                    titleTypographyProps={{ noWrap: true }}
                />
                <CardContent style={{ padding: 0, paddingLeft: 55 }}>
                    <List style={{ padding: 0 }}>
                        {item.notifications?.map(x => renderHistoricNotification(x, false, false))}
                    </List>
                </CardContent>
            </Card>
        </ListItem>
    }

    const renderHistoricNotificationsList = (): JSX.Element => <List style={{ width: '100%', padding: 0 }}>
        {(list?.length || 0) === 0 && <div style={{ width: '100%', textAlign: 'center' }}>
            <Divider variant="fullWidth" component="li" style={{ marginBottom: 10 }} />
            <DraftsOutlined className={classes.placeHolder} />
            <br />
            No historic notifications
            <Divider variant="fullWidth" component="li" style={{ marginTop: 10 }} />
        </div>}
        {list.map((item, index, list) => <>
            {index !== 0 && <Divider variant="fullWidth" component="li" />}
            {renderHistoricNotification(item)}
            {(index === list.length - 1) && <Divider variant="fullWidth" component="li" />}
        </>)}
        {(pagination.total || 0) > 0 && <div style={{ textAlign: 'center', width: '100%', opacity: 0.5 }}>
            <small>Displaying {list.length} of {pagination.total} notifications</small>
        </div>}
        {(pagination.total || 0) > 10 && pagination.total !== list.length && <>
            <Button
                style={{ margin: 5, width: '100%' }}
                variant="contained"
                fullWidth
                className={"btn-primary p-3 text-white text-capitalize"}
                type="button"
                onClick={() => handleLoadMoreNotifications()}>
                Load more
            </Button>
        </>}
    </List>

    const renderActiveLotNotificationsList = (): JSX.Element => <List style={{ width: '100%', padding: 0 }}>
        {(activeLotList?.length || 0) === 0 && <div style={{ width: '100%', textAlign: 'center' }}>
            <Divider variant="fullWidth" component="li" style={{ marginBottom: 10 }} />
            <DraftsOutlined className={classes.placeHolder} />
            <br />
            No active lot notifications
            <Divider variant="fullWidth" component="li" style={{ marginTop: 10 }} />
        </div>}
        {activeLotList.map((item, index, list) => <>
            {index !== 0 && <Divider variant="fullWidth" component="li" />}
            {renderActiveLotNotification(item)}
            {(index === activeLotList.length - 1) && <Divider variant="fullWidth" component="li" />}
        </>)}
    </List>

    const renderLotsTabButton = (): JSX.Element => <Button
        style={{ marginRight: 5, width: '100%' }}
        variant="contained"
        className={selectedTab === TabNames.Lots ? "btn-primary p-3 text-white text-capitalize" : "btn-primary p-3 text-capitalize text-white"}
        type="button"
        onClick={() => setSelectedTab(TabNames.Lots)}>
        {TabNames.Lots}
    </Button>

    const renderHistoryTabButton = (): JSX.Element => <Button
        style={{ marginLeft: 5, width: '100%', background: '#2B2B2B' }}
        variant="contained"
        className={selectedTab === TabNames.History ? "btn-primary p-3 text-white text-capitalize" : "btn-primary p-3 text-capitalize text-white"}
        type="button"
        onClick={() => setSelectedTab(TabNames.History)}>
        {TabNames.History}
    </Button >

    const renderTabs = (): JSX.Element => <div style={{ width: 350 }}>
        <Grid container style={{ padding: 10 }}>
            <Grid item xs={6}>
                {unreadNotificationCount > generalUnreadCount ? <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{ marginRight: 5, width: '100%' }}
                    badgeContent=""
                    classes={{ badge: "bg-danger badge-circle border-0" }}
                    variant="dot"
                >
                    {renderLotsTabButton()}
                </StyledBadge> : renderLotsTabButton()}

            </Grid>
            <Grid item xs={6}>
                {generalUnreadCount > 0 ? <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    style={{ marginLeft: 5, width: '100%' }}
                    badgeContent=""
                    classes={{ badge: "bg-danger badge-circle border-0" }}
                    variant="dot"
                >
                    {renderHistoryTabButton()}
                </StyledBadge> : renderHistoryTabButton()}
            </Grid>
        </Grid>
        {unreadNotificationCount > 0 && <Grid container style={{ padding: 10 }}>
            <Button
                style={{ width: '100%', textTransform: 'none' }}
                variant="contained"
                className={"btn-primary p-3 text-white"}
                type="button"
                onClick={() => handleSetAllNotificationsAsRead()}>
                {"Mark All as Read"}
            </Button>
        </Grid>}
        <Paper style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}>
            <LimitedBackdrop open={loading}>
                <CircularProgress
                    className="text-center"
                    style={{
                        margin: "auto",
                        color: settings?.Styles?.OverrideLoadingSpinnerColor
                            ? settings?.Styles?.OverrideLoadingSpinnerColor
                            : settings?.Styles?.Primary,
                    }}
                />
            </LimitedBackdrop>
            {selectedTab === TabNames.Lots && <div style={{ padding: '0px 10px' }}>{renderActiveLotNotificationsList()}</div>}
            {selectedTab === TabNames.History && <div style={{ padding: '0px 10px' }}>{renderHistoricNotificationsList()}</div>}
        </Paper>
    </div>

    const handleRefresh = (refreshHistory: boolean = true) => {
        if (open) {
            let profileId = PrembidLocalStorage.currentProfile;
            if (profileId && profileId !== '') {
                refreshLotNotifications_d(profileId, undefined, undefined, true, (response) => {
                    if (response.success === false)
                        ns.error('', "Failed to to refresh the lot notifications");

                    if (refreshHistory) {
                        refreshNotifications_d(profileId, undefined, true, (response) => {
                            if (response.success === false)
                                ns.error('', "Failed to to refresh the notifications");
                        });
                    }
                });
            }
        }
    }

    return (<>
        <Drawer anchor='right' open={open} onClose={handleClose} classes={{
            paper: classes.drawer
        }}>
            <Card className='card-transparent' >
                <CardHeader
                    title={"Notifications"}
                    subheader={<small>{(lastRefreshed && !loading) ? "Last refreshed at " + lastRefreshed : "Refreshing notifications..."}</small>}
                    action={
                        <Tooltip open={newMessages} title={newMessages ? "New message(s)." : "Refresh"} placement="left-start" PopperProps={{ style: { zIndex: 0 } }}>
                            <Button
                                onClick={() => handleRefresh()}
                                variant="text"
                                className={clsx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill", { 'btn-outline-success': newMessages })}
                                style={{ marginTop: 8 }}
                            >
                                <FaRedo />
                            </Button>
                        </Tooltip>
                    }
                    style={{ padding: 10 }}
                />
                <CardContent style={{ padding: 0 }}>
                    {renderTabs()}
                </CardContent>
            </Card>
        </Drawer>
    </>);
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        togglePanel_d: (status) => dispatch(togglePanel(status)),
        refreshNotifications_d: (profileId: string, pagination: any, includeRead: boolean, onCompletedCallback: (response) => void, withReplacement?: boolean) => dispatch(refreshNotificationsList(profileId, pagination, includeRead, onCompletedCallback, withReplacement)),
        refreshLotNotifications_d: (profileId: string, lotId: string | undefined, pagination: any, includeRead: boolean, onCompletedCallback: (response) => void) => dispatch(refreshLotNotificationsList(profileId, lotId, pagination, includeRead, onCompletedCallback)),
        setNotificationAsRead_d: (notificationId: string, onCompletedCallback: (response: any) => void) => dispatch(setNotificationAsRead(notificationId, onCompletedCallback)),
        setAllNotificationsAsRead_d: (profileId: string, onCompletedCallback: (response: any) => void) => dispatch(setAllNotificationsAsRead(profileId, onCompletedCallback)),
        deleteNotification_d: (notificationId: string, onCompletedCallback: (response: any) => void) => dispatch(deleteNotification(notificationId, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({
    open: state.notificationPanel.open,
    pagination: state.notificationPanel.pagination,
    list: state.notificationPanel.list,
    activeLotList: state.notificationPanel.activeLotList,
    loading: state.notificationPanel.loading,
    unreadNotificationCount: state.notificationPanel.unreadNotificationCount,
    generalUnreadCount: state.notificationPanel.generalUnreadCount,
    lastRefreshed: state.notificationPanel.lastRefreshed,
    newMessages: state.notificationPanel.newMessages,
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPanel);
