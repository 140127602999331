import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Paper, DialogContentText } from "@mui/material";
import {
    clearApiResponses,
    clearBidResponses,
} from "@/pages/Bids/store/Bidding";
import { connect } from "react-redux";
import { RegisteredUserStatusEnum } from "@/helpers/RegisteredUserStatusEnum";
import { submitBid } from "@/pages/Bids/store/Bidding";
import { PrembidLocalStorage } from "@/helpers/PrembidLocalStorage";
import CurrencyLabel from "@/components/CurrencyComponent/CurrencyLabel";
import ns from "@/helpers/NotificationService";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import { sortBy, sum } from "lodash";
import ConfirmationDialog from "../Modal/confirmationModal";
import { handleSetWishlistBiddingNotification } from "../../pages/MyActivity/store/MyActivity";
import { LoadingButton } from "@mui/lab";

interface Props {
    submitBid_d: (
        auctionId: string,
        lotId: string,
        amount: number,
        profileId: string,
        isTenderSubmission: boolean,
        onCompletedCallback: (res: any) => void
    ) => void;
    clearResponses_d: () => void;
    clearBidResponses_d: () => void;
    auctionId: string;
    lotId: string;
    validateResult: any;
    success: boolean;
    bidError: boolean;
    bidErrorText: string;
    bidAmount: number;
    profileId: string;
    onBidSuccessfullySubmitted: (data: any) => void;
    handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => void,
    wishlistNotificationFlagObject: any,
    biddingCalculation: any;
    commission: any;
    settings: any;
}

const BidButton: React.FC<Props> = (props) => {
    const {
        clearResponses_d,
        clearBidResponses_d,
        submitBid_d,
        auctionId,
        lotId,
        validateResult,
        success,
        bidError,
        bidErrorText,
        bidAmount,
        profileId,
        onBidSuccessfullySubmitted,
        handleSetWishlistBiddingNotification_d,
        wishlistNotificationFlagObject,
        biddingCalculation,
        commission,
        settings
    } = props;

    const [amount, setAmount] = React.useState<number>(0);
    // Add this state to the BidButton component
    const [confirmed, setConfirmed] = React.useState(false);
    const [confirmationRequired, setConfirmationRequired] = React.useState(false);
    const [isBusy, setIsBusy] = React.useState(false);
    const [bid, setBid] = React.useState({
        status: RegisteredUserStatusEnum.NotRegistered,
        reason: "",
    });

    const [open, setOpen] = React.useState(false);
    const [vatPercentage, setVatPercentage] = useState<number>(15);

    React.useEffect(() => {
        clearBidResponses_d();
    }, [profileId]);

    React.useEffect(() => {
        setAmount(bidAmount);
        setConfirmationRequired(false);
        setConfirmed(false);
    }, [bidAmount]);

    React.useEffect(() => {
        if (success) {
            clearResponses_d();
            setIsBusy(false);
        }
    }, [success]);

    React.useEffect(() => {
        if (bidError) {
            if (bidErrorText !== "" && bidErrorText !== undefined) {
                ns.error("", bidErrorText)
            }
            clearResponses_d();
            setIsBusy(false);
        }
    }, [bidError]);

    React.useEffect(() => {
        if (validateResult && validateResult.length > 0) {
            const result = validateResult.filter(
                (result) => result.auctionId === auctionId
            )[0];
            if (result) {
                if (
                    result.status === RegisteredUserStatusEnum.Approved &&
                    PrembidLocalStorage.currentUser
                ) {
                } else {
                    setBid({
                        ...bid,
                        status: result.status,
                        reason: result.reason,
                    });
                }
            }
        }
    }, [validateResult]);

    const buyersCommissionAmount = useMemo(() => { return Number(amount) * commission / 100; }, [amount, commission]);

    const determineCalculationAmount = (basedOn: any, subTotal: number, custombidAmount: number, amount: number, type: any) => {
        let total = custombidAmount;
        if (basedOn === "cumulativeTotal") total = subTotal;

        switch (type) {
            case "percentage": return total * amount / 100;
            default: return amount;
        }
    }

    const additionCostAmounts = useMemo<number[]>(() => {
        if ((biddingCalculation?.calculations?.length ?? 0) === 0) return [];

        let orderdedList = sortBy(biddingCalculation?.calculations, ["order"]);

        let totals: number[] = [];

        let subTotal = Number(amount) + buyersCommissionAmount;

        for (let i = 0; i < orderdedList.length; i++) {
            let item = orderdedList[i];
            let calculationAmount = determineCalculationAmount(item.basedOn, subTotal, amount, item.amount, item.type);
            subTotal += calculationAmount;
            totals.push(calculationAmount);
        }

        return totals;
    }, [amount, buyersCommissionAmount, biddingCalculation?.calculations]);

    const totalExVatAmount = useMemo(() => sum(additionCostAmounts ?? [0]) + Number(amount) + buyersCommissionAmount, [amount, buyersCommissionAmount, additionCostAmounts]);

    const vatAmount = useMemo(() => {
        return totalExVatAmount * vatPercentage / 100;
    }, [totalExVatAmount])

    const total = useMemo(() => {
        return totalExVatAmount + (biddingCalculation?.applyVat ? vatAmount : 0);
    }, [totalExVatAmount, vatAmount])

    const onOpen = () => {
        setOpen(true);
    };

    const onCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (confirmed) {
            setIsBusy(true);
            submitBid_d(
                auctionId,
                lotId,
                Number(amount),
                profileId,
                false,
                (res) => {
                    if (res?.success) {
                        ns.success("", "Bid successfully submitted");
                        onBidSuccessfullySubmitted(res.response);
                        let temp = { ...wishlistNotificationFlagObject };
                        temp.biddingActivityFlag = true;
                        handleSetWishlistBiddingNotification_d(temp);
                    }
                    setIsBusy(false);
                    setConfirmed(false);
                    setConfirmationRequired(false);
                }
            );
        }
    }, [confirmed]);

    const submitBid = (confirmationRequired, confirmed) => {
        if (!confirmationRequired) setConfirmationRequired(true);
        else if (!confirmed) setConfirmed(true);
    };

    return <React.Fragment>
        <Grid
            className="mb-3"
            container
            spacing={2}
            style={{ placeItems: 'center' }}>
            <Grid item xs={6}>
                <h5 className="text-black">Quick Bid</h5>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={confirmationRequired ? 8 : 12}>
                        <LoadingButton
                            style={{ height: 56 }}
                            onClick={() => submitBid(confirmationRequired, confirmed)}
                            fullWidth
                            variant="contained"
                            className={
                                confirmationRequired
                                    ? "btn-success p-3 text-white text-capitalize"
                                    : "btn-primary p-3 text-white text-capitalize"
                            }
                            loading={isBusy}
                        >
                            {confirmationRequired ? (
                                "Confirm"
                            ) : (
                                <CurrencyLabel
                                    style={{
                                        overflow: "hidden",
                                        whiteSpace: "wrap",
                                    }}
                                    value={amount}
                                    textColour={"text-white"}
                                />
                            )}
                        </LoadingButton>
                    </Grid>
                    {confirmationRequired && (
                        <Grid item xs={4}>
                            <Button
                                onClick={() => setConfirmationRequired(false)}
                                style={{ height: 56, width: '100%' }}
                                variant="contained"
                                className="btn-danger p-3 text-white text-capitalize"
                            >
                                <span className="btn-wrapper--icon">
                                    <FaTimes />
                                </span>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>

        </Grid>
        {biddingCalculation && <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h5 className="text-black text-left">Including all fees</h5>
                    <Tooltip arrow title="Click for Info" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            size="large"
                            className="btn-white d-30 btn-pill p-0 btn-icon btn-animated-icon align-items-right"
                            onClick={onOpen}
                            style={{ transform: 'translateY(-5px)' }}
                        >
                            <FaInfoCircle style={{ fontSize: "20px", color: '#2b2b2b' }} color={"#2b2b2b"} />
                        </Button>
                    </Tooltip>
                </div>
                <CurrencyLabel value={total} />
            </div>

            <ConfirmationDialog
                open={open}
                okButtonText="Confirm"
                cancelButtonText="Cancel"
                title="All Fees Breakdown"
                content={
                    <>
                        <DialogContentText>
                            The total amount you are a liable to pay if your bid is
                            accepted.
                        </DialogContentText>
                        <DialogContentText>
                            See breakdown below
                        </DialogContentText>

                        <Grid className="p-1">
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" style={{ width: "100%" }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Bid Amount</TableCell>
                                            <TableCell align="right">
                                                <CurrencyLabel value={amount} textColour={settings?.Styles?.Primary}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Buyers Commission</TableCell>
                                            <TableCell align="right"><CurrencyLabel value={buyersCommissionAmount} /></TableCell>
                                        </TableRow>

                                        {biddingCalculation?.calculations?.map((calculation: any, index: number) => (
                                            <TableRow>
                                                <TableCell>{calculation.description}</TableCell>
                                                <TableCell align="right"><CurrencyLabel value={additionCostAmounts[index]} /></TableCell>
                                            </TableRow>))}

                                        {biddingCalculation?.applyVat && <TableRow>
                                            <TableCell>VAT ({vatPercentage.toFixed(2)} %)</TableCell>
                                            <TableCell align="right">
                                                <CurrencyLabel value={vatAmount} />
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <DialogContentText
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 24,
                                paddingTop: 10
                            }}
                        >
                            Total Amount
                            <CurrencyLabel
                                textColour={settings?.Styles?.Primary}
                                value={total}
                            />
                        </DialogContentText>
                        <DialogContentText>Happy Bidding!</DialogContentText>
                    </>
                }
                onCancel={onCancel}
            />
        </>}
    </React.Fragment>
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearResponses_d: () => dispatch(clearApiResponses()),
        clearBidResponses_d: () => dispatch(clearBidResponses()),
        submitBid_d: (auctionId: string, lotId: string, amount: number, profileId: string, isTenderSubmission: boolean, onCompletedCallback: (res: any) => void) => dispatch(submitBid(auctionId, lotId, amount, profileId, isTenderSubmission, onCompletedCallback)),
        handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetWishlistBiddingNotification(payload, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    success: state.bidding.success,
    validateResult: state.participateButton.validateResult,
    bidError: state.bidding.error,
    bidErrorText: state.bidding.errorText,
    profileId: state.loginForm.changeProfileId,
    settings: state.settings.settings,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject,
});

export default connect(mapStateToProps, mapDispatchToProps)(BidButton);
