import { FC, MouseEventHandler, memo } from 'react';
import { Carousel } from "react-responsive-carousel";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useImageAndVideoCarouselStyles } from '../ImageAndVideoCarousel.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/Store';
import { Card, Skeleton } from '@mui/material';

require("@/assets/css/carousel.css");

interface ImageCarouselProps {
    images: Array<string>;
    showLoading?: boolean;
}

const ImageCarousel: FC<ImageCarouselProps> = (props) => {
    const { images, showLoading = false } = props;

    const { settings } = useSelector((state: RootState) => state.settings);

    const { classes: { image, arrows, leftArrow, rightArrow, thumbnail, imageSlide } } = useImageAndVideoCarouselStyles();

    const renderArrowPrev = (onClickHandler: MouseEventHandler<HTMLButtonElement>, hasPrev: boolean, label: string) =>
        hasPrev && (
            <button
                type="button"
                className={`${arrows} ${leftArrow}`}
                onClick={onClickHandler}
                aria-label={label}>
                <IoIosArrowBack />
            </button>
        );

    const renderArrowNext = (onClickHandler: MouseEventHandler<HTMLButtonElement>, hasNext: boolean, label: string) =>
        hasNext && (
            <button
                type="button"
                className={`${arrows} ${rightArrow}`}
                onClick={onClickHandler}
                aria-label={label}>
                <IoIosArrowForward />
            </button>
        );

    const customRenderThumb = (children) =>
        children.map((item, idx) => (
            showLoading ? (
                <Skeleton key={idx} animation="wave" variant="rectangular" height={70} width={70} />
            ) : (
                <img
                    key={item?.key}
                    className={thumbnail}
                    src={item?.props?.url}
                    alt="thumbnail"
                    style={{
                        border: '2px solid ' + settings?.Styles?.Primary,
                    }}
                />
            )
        ));

    const ImageSlide = ({ url }: { url: string }) => (
        <div className="carousel-wrapper">
            <img
                src={url}
                className={imageSlide}
                alt="carousel slide"
            />
        </div>
    );

    return (
        <Card className={image}>
            <Carousel
                thumbWidth={70}
                stopOnHover={true}
                infiniteLoop={true}
                autoPlay={false}
                emulateTouch={true}
                showStatus={false}
                useKeyboardArrows={true}
                showThumbs={true}
                dynamicHeight={false}
                showArrows={true}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
                renderThumbs={customRenderThumb}
            >
                {
                    showLoading ? (
                        Array.from({ length: 10 }).map((_, idx) => (
                            <ImageSlide key={idx} url={"https://via.placeholder.com/1928x1240?text=Loading"} />
                        ))
                    ) : (
                        images.map((imageUrl, idx) => (
                            <ImageSlide key={idx} url={imageUrl} />
                        ))
                    )
                }
            </Carousel>
        </Card>
    );
};

export default memo(ImageCarousel);
