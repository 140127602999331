import { memo } from 'react';
import CustomBidButton from '@/components/Buttons/CustomBidButton';
import { Skeleton } from '../../library/Skeleton';

type CustomBidButtonContainerProps = {
    showLoading?: boolean;
    commission: number | null | undefined;
    biddingCalculation: any;
    auctionId: string;
    lotId: string;
    onSuccess: (amount: number, paddleNumber: string) => void;
}

const CustomBidButtonContainer = (props: CustomBidButtonContainerProps) => {
    const {
        showLoading = false,
        commission,
        biddingCalculation,
        auctionId,
        lotId,
        onSuccess
    } = props;

    return (<>
        {
            showLoading ? (
                <Skeleton className="mb-3" animation="wave" variant="rectangular" height="56px" width="100%" />
            ) : (
                <CustomBidButton
                    onBidSuccessfullySubmitted={onSuccess}
                    auctionId={auctionId}
                    lotId={lotId}
                    commission={commission}
                    biddingCalculation={biddingCalculation}
                />
            )
        }
    </>
    )
}

export default memo(CustomBidButtonContainer);