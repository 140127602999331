import { Grid } from "@mui/material";
import { FC, memo } from "react";

export interface FeatureItemProps {
    feature: any;
    idx: number;
}

const FeatureItem: FC<FeatureItemProps> = ({ feature, idx }) => {
    return <div key={"featureId" + idx}>
        <h5
            className="mb-2 text-black"
            style={{ whiteSpace: 'pre-wrap' }}>
            {feature.key}
        </h5>
        <h5 className="text-black-75 mb-2" style={{ whiteSpace: 'pre-wrap' }}>
            {feature.value}
        </h5>
    </div>
};

export default memo(FeatureItem);