import React from 'react';
import { connect, } from 'react-redux';
import {
    Container,
    Grid,
} from '@mui/material';
import { FaUser, FaSignInAlt, FaRegHandshake, FaCheckCircle, FaMoneyBillAlt, FaSearch } from "react-icons/fa";
import HeaderBlock from '@/components/HeaderBlock/HeaderBlock';
import useStyles from './HowItWorksStyles';
import { SectionTitle } from '@/components/SectionTitle';

interface Props {
    settings: any
}

const HowItWorks: React.FC<Props> = props => {

    const { classes } = useStyles();

    const {
        settings,
    } = props;

    //Will change this to a useState setting it as a constant for now as not to cause errors in the console
    const headerBlock = {
        header: '',
        description: '',
        timerHeader: '',
        timerDescription: ''
    };

    const tutorialVideos = () => {
        window.open(settings.ProductWebsite + "/tutorials");
    }

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
            <div className="mb-5">
                <HeaderBlock imageSrc={settings.VendorBannerUrl} />
            </div>

            <div className="text-center mb-5" >
                <SectionTitle title={"How it Works?"} subTitle={<><a onClick={tutorialVideos} className={classes.link} style={{ textDecoration: 'underline' }}>Click here</a> to view the tutorial videos</>} />
            </div>

            <div className="pb-5">
                <Container maxWidth={false} className={classes.containerWidth}>
                    <h3 className="font-weight-bold" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>How our System Works?</h3>
                    <h4 className="font-weight mb-5" style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>Please see the below process flow to get a better understanding of how to use our system</h4>
                    <div className="timeline-list">
                        <div className="timeline-item timeline-item-icon">
                            <div className="timeline-item--content">
                                <div className="timeline-item--icon-wrapper rounded-pill bg-info text-white">
                                    <FaSignInAlt />
                                </div>
                                <h5 className=" mb-2" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Sign Up</h5>
                                <h6 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                    Sign Up on our website, or on one of our mobile applications (if available) in the App Store and Google Play store.
                                </h6>
                            </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                            <div className="timeline-item--content">
                                <div className="timeline-item--icon-wrapper rounded-pill bg-danger text-white">
                                    <FaSearch />
                                </div>
                                <h5 className=" mb-2" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Browse</h5>
                                <h6 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                    {"Browse the current and upcoming " + settings.AuctionName + "s and their associated " + settings.LotName + "s."}
                                </h6>
                            </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                            <div className="timeline-item--content">
                                <div className="timeline-item--icon-wrapper rounded-pill bg-warning text-white">
                                    <FaRegHandshake />
                                </div>
                                <h5 className=" mb-2" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Register</h5>
                                <h6 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                    {"Register for the " + settings.AuctionName + "s that have " + settings.LotName + "s that you wish to bid/tender on. Please follow the registration wizard as it guides you through registration."}
                                </h6>
                            </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                            <div className="timeline-item--content">
                                <div className="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                                    <FaCheckCircle />
                                </div>
                                <h5 className=" mb-2" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Wait for approval</h5>
                                <h6 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                    {"Please be patient while we review all the related information. You will be notified once approved, or communication will be provided if you are not."}

                                </h6>
                            </div>
                        </div>
                        <div className="timeline-item timeline-item-icon">
                            <div className="timeline-item--content">
                                <div className="timeline-item--icon-wrapper rounded-pill bg-night-sky text-white">
                                    <FaMoneyBillAlt />
                                </div>
                                <h5 className=" mb-2" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Bid/Tender</h5>
                                <h6 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                    {"Subject to approval, you are now ready to bid/tender on the " + settings.LotName + "s you are interested in. Good luck and happy bidding/tendering."}
                                </h6>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
