import { FC, memo, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
export interface ScreenWrapperProps {
	children?: ReactNode;
}

const ScreenWrapper: FC<ScreenWrapperProps> = ({ children }) => {
	const { settings } = useSelector((state: RootState) => state.settings);

	return (
		<div
			style={{
				background: settings?.Styles?.OverridePageBackgroundColor
					? settings?.Styles?.OverridePageBackgroundColor
					: "#ffffff",
			}}>
			{children}
		</div>
	);
};

export default memo(ScreenWrapper);
