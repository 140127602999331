import React, { FC, memo, CSSProperties, useState } from "react";

import { useCardActionButtonsStyles } from "./CardActionButtons.styles";
import { AuctionTypeEnum } from "@/helpers/AuctionTypeEnum";
import { Button } from "@mui/material";
import PopperComponent from "@/components/Popper/PopperComponent";
import { useNavigator } from "@/helpers/hooks/useNavigator";
import { LotStatus } from "../../../helpers/LotStatusEnum";
import { BiddingTable } from "../../BiddingTable";

export interface CardActionButtonsProps {
  containerStyle?: CSSProperties;
  isActive: boolean;
  auctionType: AuctionTypeEnum;
  auctionId: string;
  lotId: string;
  overrideShowBidButtonForCompletedLots: boolean;
    status?: LotStatus;
}

const CardActionButtons: FC<CardActionButtonsProps> = ({
  containerStyle,
  auctionType,
  isActive,
  auctionId,
  lotId,
  overrideShowBidButtonForCompletedLots,
  status,
}) => {
  const styles = useCardActionButtonsStyles();

  const { btnBidRoute, btnViewLotDetailsRoute } = useNavigator({
    lotId,
    auctionId,
  });

    const [quickBidAnchorEl, setQuickBidAnchorEl] = useState(null);

    const handleQuickBidPopperClick = (event) => {
        setQuickBidAnchorEl(event.currentTarget);
    };

    const handleQuickBidPopperClose = () => {
        setQuickBidAnchorEl(null);
    };

  return (
    <div className={styles.classes.actionButtonsContainer} style={containerStyle}>
      {isActive && (
        <Button
          href=""
          fullWidth
          onClick={btnViewLotDetailsRoute}
          className={`${styles.classes.actionButton} btn-primary p-2 text-white text-capitalize`}
          style={{ marginLeft: 2 }}
        >
            <span className="btn-wrapper--label">{Number(auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Tender/Info" : "Bid/Info"}</span>
        </Button>
          )}

          {isActive && Number(auctionType) !== Number(AuctionTypeEnum.Streamed.toString()) && <>
              <Button
                  href=""
                  fullWidth
                  style={{ background: '#2b2b2b' }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className={`${styles.classes.actionButton} btn-primary p-2 text-white text-capitalize`}
                  onClick={handleQuickBidPopperClick}>
                  {quickBidAnchorEl && (
                      <PopperComponent
                          popperStyle={{ minWidth: 400 }}
                          headerText={""}
                          anchorEl={quickBidAnchorEl}
                          element={<BiddingTable auctionId={auctionId} lotId={lotId} showCompactView={true} />}
                          handlePopperComponentClose={handleQuickBidPopperClose}
                      />
                  )}
                  <span className="btn-wrapper--label">{Number(auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Quick Tender" : "Quick Bid"}</span>
              </Button>
          </>
          }
    </div>
  );
};

export default memo(CardActionButtons);
