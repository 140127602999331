import { CircularProgress } from "@mui/material";
import { CSSProperties, ReactNode, Suspense, memo } from "react";
import { useSelector } from "react-redux";

type LoadingProps = {
    loading: boolean;
    size?: string | number;
    thickness?: number;
    children?: ReactNode;
    type?: "spinner" | "text" | "both";
    style?: CSSProperties;
    loadingText?: string;
    loadingTextStyle?: CSSProperties;
    errorText?: string;
}

const Loading = (props: LoadingProps) => {
    const { settings } = useSelector((state: any) => state.settings);
    const { loading = "spinner", size, thickness, children, style, loadingText, loadingTextStyle,  errorText } = props;

    const renderLoading = () => <div
        style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...(style ?? {}),
        }}>
        <CircularProgress
            size={size}
            thickness={thickness}
            style={{
                color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary
            }} />
        {loadingText && <div
            className="mt-2"
            style={{
                color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary,
                ...(loadingTextStyle ?? {})

            }}>{loadingText}</div>}
        {errorText && <div className="mt-2 text-danger">{errorText}</div>}    </div>

    return <Suspense fallback={renderLoading()}>
        {loading ? renderLoading() : children}
    </Suspense>
}

export default memo(Loading);