import { CSSProperties, FC, memo } from "react";
import { Skeleton as DefaultSkeleton, SkeletonProps as DefaultSkeletonProps } from "@mui/material";
import { useSkeletonStyles } from "./Skeleton.styles";

export interface SkeletonProps {
    containerStyle?: CSSProperties;
}

type DefaultProps = SkeletonProps & DefaultSkeletonProps;

const Skeleton: FC<DefaultProps> = ({ containerStyle, ...otherProps }) => {
    const { classes: { container } } = useSkeletonStyles();
    const finalProps = { classes: container, style: containerStyle, animation: "pulse", ...otherProps } as DefaultProps;
    return <DefaultSkeleton {...finalProps} />;
};

export default memo(Skeleton);