import { Grid } from "@mui/material";
import { FC, memo } from "react";
import FeatureItem from "./sub-components/FeatureItem";

export interface LotFeatureItemsProps {
    features: any[]
}

const LotFeatureItems: FC<LotFeatureItemsProps> = ({ features }) => {
    return <Grid container spacing={2}>
        {
            features.map((feature, idx) => (
                <Grid item xs={4} key={idx}>
                    <FeatureItem feature={feature} idx={idx} />
                </Grid>
            ))
        }
    </Grid>
};

export default LotFeatureItems;