import React, { FC, memo, CSSProperties } from "react";

import { useLotFeaturesStyles } from "./LotFeatures.styles";
import { Typography } from "@mui/material";

export interface LotFeaturesProps {
  containerStyle?: CSSProperties;
  lotFeatures?: any;
}

const LotFeatures: FC<LotFeaturesProps> = ({
  lotFeatures = [],
}) => {
    const { classes: { lotFeaturesContainer, lotFeatureContainer, lotFeatureText } } = useLotFeaturesStyles();

  return (
    <div className={lotFeaturesContainer}>
      {lotFeatures
        .filter((index: number) => index <= 3)
        .map((lotFeature, index) => (
          <div className={lotFeatureContainer} key={`lotF-${index}`}>
            <Typography className={lotFeatureText}>
              {`${lotFeature.key}`}
            </Typography>
            <Typography
              className={lotFeatureText}
              style={{ color: "#5a5a5a"}}
            >
                    {lotFeature.value.length >= 15 ? lotFeature.value.slice(0, 15) + "..." : lotFeature.value}
            </Typography>
          </div>
        ))}
    </div>
  );
};

export default memo(LotFeatures);
