import { FC, memo, useCallback } from "react";
import { Avatar, Box, Card, Divider, Stack } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { isMobile } from 'react-device-detect';
import { LotContact } from "prembid-shared-library-npm/types";
import { useLotContactListStyles } from "../LotContactList.styles";
import { RootState } from "../../../store/Store";
import { useSelector } from "react-redux";

export type LotContactDisplayProps = LotContact;

const LotContactDisplay: FC<LotContactDisplayProps> = ({ firstName, lastName, email, imageUrl, phoneNumber }) => {
    const { classes } = useLotContactListStyles();

    const { settings } = useSelector((state: RootState) => state.settings);

    const renderPhoneNumber = useCallback(() => {
        return (
            <Stack alignItems={'center'} className={classes.link}>
                <CallIcon className={classes.buttonIcon} style={{ background: settings?.Styles?.Primary }} />
                <h5 className={classes.buttonLabel}>{isMobile ? 'Call' : phoneNumber}</h5>
            </Stack>
        )
    }, [classes.buttonIcon, classes.buttonLabel, classes.link, phoneNumber, settings?.Styles?.Primary])

    return (
        <Card raised={true}>
            <Stack direction={'row'}>
                <Box className={classes.imageContainer}>
                    <Avatar src={imageUrl} className={classes.image} />
                </Box>
                <Box className={classes.infoContainer}>
                    <Stack>
                        <h5 className={classes.contactHeading}>{`${firstName} ${lastName}`}</h5>
                        <Divider />
                        <Stack direction={'row'} className={classes.contactButtonsContainer}>
                            {
                                isMobile ? (
                                    <a href={`tel:${phoneNumber}`}>
                                        {renderPhoneNumber()}
                                    </a>
                                ) : renderPhoneNumber()
                            }
                            <a href={`mailto:${email}`}>
                                <Stack alignItems={'center'}>
                                    <MailOutlineIcon className={classes.buttonIcon} style={{ background: settings?.Styles?.Primary }} />
                                    <h5 className={classes.buttonLabel}> Email </h5>
                                </Stack>
                            </a>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
};

export default memo(LotContactDisplay);
