import { makeStyles } from "tss-react/mui";

export const useTabBoxStyles = makeStyles()((theme) => ({
    tabListIndicator: {
        backgroundColor: "#cccccc",
        color: "#ffffff",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        marginRight: 0,
        whiteSpace: 'wrap'
    },
    tabListItemRoot: {
        color: "#ffffff",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        marginRight: 0,
        whiteSpace: 'wrap'
    },
}));